import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ConvertService } from 'src/app/shared/services/convert.service';

@Component({
  selector: 'app-image-cropper-component',
  templateUrl: './image-cropper-component.component.html',
  styleUrls: ['./image-cropper-component.component.scss']
})
export class ImageCropperComponentComponent implements OnInit {

  title = 'ngImageCrop';
  croppedImage: any = '';
  photoWidth;

  @Input() webcamImage: any;
  @Input() image: any;
  @Input() studentKey: string;
  @Output() croppedImageEmit = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  imageCropped(event: ImageCroppedEvent) {
    this.photoWidth = event.width;
    this.croppedImage = event.base64;
    fetch(this.croppedImage)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], ConvertService.pageKey() + ".png", { type: "image/png" })
        this.croppedImageEmit.emit(file);
      });
  }

  btnCancelClicked() {
    this.croppedImageEmit.emit(null);
  }

  imageLoaded() {
    /* show cropper */
  }

  cropperReady() {
    /* cropper ready */
  }

  loadImageFailed() {
    /* show message */
  }

  btnSaveClicked() {

  }

}
