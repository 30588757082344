import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Report } from 'src/app/core/stores/report.store';
import { PrintService } from 'src/app/shared/services/print.service';
import { Environment } from 'src/app/core/stores/environment';

@Component({
  selector: 'app-report-shift-summary-dialog',
  templateUrl: './report-shift-summary-dialog.component.html',
  styleUrls: ['./report-shift-summary-dialog.component.scss']
})
export class ReportShiftSummaryDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ReportShiftSummaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: Report,
    private ps: PrintService,
    private env: Environment,
  ) { }

  ngOnInit() {
    this.store.fetchReceiptByTerm(this.data.key);
    this.store.fetchDialShiftCashReceiveRiels(this.data.key, (riels) => { })
    this.store.fetchDialShiftCashReceiveDollars(this.data.key, (dollars) => { })
  }

  print() {
    this.ps.print("#printD", "a4");
  }

  check() {
    // let isEqual = false;
    // const moneyCount = (MappingService.sum(this.store.moneyRiels, "total") / 4000) + MappingService.sum(this.store.moneyDollars, "total")
    // isEqual = ConvertService.toFloatFixed(this.store.totalAmount, 1) - ConvertService.toFloatFixed(moneyCount, 1) == 0;

  }
}
