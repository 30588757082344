import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobxAngularModule } from 'mobx-angular';
import { MaterialModule } from './ng-material/material.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimaryButtonComponent } from './components/primary-button/primary-button.component';
import { NguCarouselModule } from '@ngu/carousel';
import { UserCampusPipe } from './pipes/user-campus.pipe';
import { ConfirmPaymentModalComponent } from './components/confirm-payment-modal/confirm-payment-modal.component';
import { SumArrayPipe } from './pipes/sum-array.pipe';
import { SumTuitionFeePipe } from './pipes/sum-tuition-fee.pipe';
import { DeleteComponent } from './components/delete/delete.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { EmptyComponent } from './components/empty/empty.component';
import { DateOnlyPipe, KhmerDatePipe, TimeObjectToDatePipe } from './pipes/date-only.pipe';
import { FilterReportByCampusPipe } from './pipes/filter-report-by-campus.pipe';
import { SumReceiptBalancePipe } from './pipes/sum-receipt-balance.pipe';
import { attendenceSubjectPipe, attNotePipe, canConfirmAttPipe, InstructorMonthlyAttendancePipePipe, MonthNamePipe, numToKhNumPipe, RemarkAttendencePipe, roundDownPipe, ScanTimePipe, studentAttendanceAPipe, studentAttendanceDPipe, studentAttendanceMonthTotalAPipe, studentAttendanceMonthTotalPPipe, studentAttendancePPipe, studentAttendanceStatisticTotalAPipe, studentAttendanceStatisticTotalPPipe, studentAttendanceStatisticYearlyTotalAPipe, studentAttendanceStatisticYearlyTotalAPPipe, studentAttendanceStatisticYearlyTotalPipe, studentAttendanceTotalAPipe, studentAttendanceTotalPPipe, studentAttendanceYearTotalAPipe, StudentAttendencePipe, StudentAttendenceRemarkPipe, studentDayAttendanceColPipe, studentDayAttendancePipe, SumAttendencePipe, SumReportPipe } from './pipes/sum-report.pipe';
import { DateTimePipe } from './pipes/date-time.pipe';
import { PucButtonComponent } from './components/puc-button/puc-button.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { ConfirmSuccessComponent } from './components/confirm-success/confirm-success.component';
import { ConfirmDeleteComponent } from './components/confirm-delete/confirm-delete.component';
import { ConfirmApprovalComponent } from './components/confirm-approval/confirm-approval.component';
import { HeaderTabsComponent } from './components/header-tabs/header-tabs.component';
import { DaysSchedulePipe } from './pipes/DaysSchedulePipe';
import { FilterShiftPipe } from './pipes/filter-shift.pipe';
import { DateMediumPipe } from './pipes/date-medium.pipe';
import { FilterCampusPipe } from './pipes/filter-campus.pipe';
import { DaySelectedPipe } from './pipes/day-selected.pipe';
import { DocRefDatePipe } from './pipes/doc-ref-date.pipe';
import { DocRefPipe } from './pipes/doc-ref.pipe';
import { EmptyDataComponent } from './components/empty-data/empty-data.component';
import { ProductInfoPipe } from './pipes/product-info.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MoreLoadingComponent } from './components/more-loading/more-loading.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { FilterScholarshipPipe } from './pipes/filter-scholarship.pipe';
import { ScrollableDirective } from './directives/scrollable.directive';
import { SumOtherFeePipe } from './pipes/sum-other-fee.pipe';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule } from '@angular/common/http';
import { FileListPreviewComponent } from './components/file-list-preview/file-list-preview.component';
import { StudentScholarshipCardComponent } from './components/student-scholarship-card/student-scholarship-card.component';
import { PreviewImageComponent } from './components/preview-image/preview-image.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { InvoicePrintFormComponent } from './components/invoice-print-form/invoice-print-form.component';
import { AddMathtypeInputComponent } from './components/add-mathtype-input/add-mathtype-input.component';
import { AddTextInputComponent } from './components/add-text-input/add-text-input.component';
import { QuestionDataTextComponent } from './components/question-data-text/question-data-text.component';
import { QuillModule } from 'ngx-quill';
import { FilterArrayPipe } from './pipes/filter-array.pipe'
import { KatexModule } from 'ng-katex';
import { TextDisplayComponent } from './components/text-display/text-display.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FilterSchoolFeePipe } from './pipes/filter-school-fee.pipe';
import { FilterSessionShowPipe } from './pipes/filter-session-show.pipe';
import { SumTestingSectionPipe } from './pipes/sum-testing-section.pipe';
import { BatchGroupLevelPipe, BatchGroupPipe, DayTextPipe, FilterHourPipe, TotalSAttendencePipe } from './pipes/batch-group.pipe';
import { TotalEnrollPipe } from './pipes/total-enroll.pipe';
import { TotalWorkPipe } from './pipes/total-work.pipe';
import { InstructorNamePipe, StudentNamePipe, TotalSOnlinePipe } from './pipes/total-student-online.pipe';
import { SelectedStudentTestingPipe } from './pipes/selected-student-testing.pipe';
import { FilterDataPipe } from './pipes/filter-data.pipe';
import { FilterStudentLevelPipe } from './pipes/filter-student-level.pipe';
import { NgApexchartsModule } from "ng-apexcharts";
import { BarSeriesPipe, ChartTypePipe, ChartXaxisPipe, LabelsPipe, SeriesPipe } from './pipes/chart.pipe';
import { FindGradePipe, GroupByGradePipe, SumGradePipe, SumMultiplierGradePipe, SumSemesterGradePipe } from './pipes/find-grade.pipe';
import { GetSchoolConfigPipe } from './pipes/get-school-config.pipe';
import { ReportReceiptSummaryDialogComponent } from './components/report-receipt-summary-dialog/report-receipt-summary-dialog.component';
import { ReportShiftSummaryDialogComponent } from './components/report-shift-summary-dialog/report-shift-summary-dialog.component';
import { SumDiscountArrayPipe } from './pipes/sum-discount-array.pipe';
import { SumScholarshipArrayPipe } from './pipes/sum-scholarship-array.pipe';
import { ConfirmAccountStudentComponent } from './components/confirm-account-student/confirm-account-student.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { LightboxModule } from 'ngx-lightbox';
import { WebcamModule } from 'ngx-webcam';
import { ImageCropperModule } from 'ngx-image-cropper';
 import { CameraComponentComponent } from './components/open-camera-dialog/camera-component/camera-component.component';
import { ImageCropperComponentComponent } from './components/open-camera-dialog/image-cropper-component/image-cropper-component.component';
import { OpenCameraDialogComponent } from './components/open-camera-dialog/open-camera-dialog.component';
import { GenerateRemarkColorCssClassPipe, RemarkColorClassNoBackgroundPipe } from './pipes/generate-remark-color-css-class.pipe';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { FindItsAttendanceStatPipe, FindRevokedPermissionDayPipe } from './pipes/find-its-attendance-stat.pipe';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgPipesModule } from 'ngx-pipes';
import { GetStudentInfoComponent } from './components/get-student-info/get-student-info.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { IdentifiedSelectionOfPermissibleSessionCardPipe } from './pipes/identified-selection-of-permissible-session-card.pipe';
import { GetFirstLetterProfilePipe } from './pipes/get-first-letter-profile.pipe';
import { FindAttConfirmLogsPipe } from './pipes/find-att-confirm-logs.pipe';
import { GenerateAuditDescriptionPipe } from './pipes/generate-audit-description.pipe';
import { ExtractFilenameFromStringPipe, imageDisplaySizePipe, replaceStorageIdPipe } from './pipes/extract-filename-from-string.pipe';
import { GetPolicyRemarksAlongWithEmployeeTypePipe } from './pipes/get-policy-remarks-along-with-employee-type.pipe';
import { GetPolicyRemarksIconPipe } from './pipes/get-policy-remarks-icon.pipe';
import { FinalJudgementAttendanceRemarksPipe } from './pipes/final-judgement-attendance-remarks.pipe';
import { ChunkPipe } from './pipes/chunk.pipe';
import { ConvertDateKeyToDatePipe } from './pipes/convert-date-key-to-date.pipe';
import { FilterOutPermissibleRemarksPipe } from './pipes/filter-out-permissible-remarks.pipe';
import { EmployeeScheduleAttRemarkPipe, FilterEmployeeHourPipe, FilterEmployeeSchedulePipe, FilterEmployeeSessionPipe, FilterScheduleHolidayPipe } from './pipes/filter-employee-hour.pipe';
import { EmployeeScheduleAttendanceComponent } from './components/employee-schedule-attendance/employee-schedule-attendance.component';
import { RejectRequestComponent } from './components/reject-request/reject-request.component';
import { GradeColorPipe } from './pipes/credit-earned.pipe';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

const components = [
  numToKhNumPipe,
  studentAttendanceStatisticYearlyTotalPipe,
  studentAttendanceStatisticYearlyTotalAPPipe,
  KhmerDatePipe,
  TimeObjectToDatePipe,
  GetStudentInfoComponent,
  studentAttendanceMonthTotalPPipe,
  studentAttendanceDPipe,
  canConfirmAttPipe,
  StudentAttendenceRemarkPipe,
  ChangePasswordComponent,
  ScanTimePipe,
  attNotePipe,
  BatchGroupLevelPipe,
  RemarkAttendencePipe,
  roundDownPipe,
  ConfirmAccountStudentComponent,
  DayTextPipe,
  attendenceSubjectPipe,
  BatchGroupPipe,
  QuestionDataTextComponent,
  AddTextInputComponent,
  AddMathtypeInputComponent,
  SumAttendencePipe,
  PageNotFoundComponent,
  PrimaryButtonComponent,
  UserCampusPipe,
  EmployeeScheduleAttendanceComponent,
  ConfirmPaymentModalComponent,
  StudentAttendencePipe,
  SumArrayPipe,
  SumTuitionFeePipe,
  DeleteComponent,
  DateFormatPipe,
  SpinnerComponent,
  EmptyComponent,
  DateOnlyPipe,
  FilterReportByCampusPipe,
  SumReceiptBalancePipe,
  SumReportPipe,
  DateTimePipe,
  PucButtonComponent,
  SanitizeHtmlPipe,
  ConfirmSuccessComponent,
  ConfirmDeleteComponent,
  ConfirmApprovalComponent,
  HeaderTabsComponent,
  DaysSchedulePipe,
  FilterShiftPipe,
  DateMediumPipe,
  FilterCampusPipe,
  DaySelectedPipe,
  DocRefPipe,
  DocRefDatePipe,
  EmptyDataComponent,
  ProductInfoPipe,
  MoreLoadingComponent,
  FilterScholarshipPipe,
  ScrollableDirective,
  SumOtherFeePipe,
  FileListPreviewComponent,
  PreviewImageComponent,
  StudentScholarshipCardComponent,
  FileSizePipe,
  InvoicePrintFormComponent,
  FilterArrayPipe,
  TextDisplayComponent,
  FilterSchoolFeePipe,
  FilterSessionShowPipe,
  SumTestingSectionPipe,
  TotalEnrollPipe,
  FilterHourPipe,
  TotalSAttendencePipe,
  TotalWorkPipe,
  TotalSOnlinePipe,
  SelectedStudentTestingPipe,
  FilterDataPipe,
  FilterStudentLevelPipe,
  SeriesPipe,
  LabelsPipe,
  ChartTypePipe,
  ChartXaxisPipe,
  BarSeriesPipe,
  FindGradePipe,
  SumGradePipe,
  SumSemesterGradePipe,
  SumMultiplierGradePipe,
  GroupByGradePipe,
  GetSchoolConfigPipe,
  ReportReceiptSummaryDialogComponent,
  ReportShiftSummaryDialogComponent,
  SumDiscountArrayPipe,
  SumScholarshipArrayPipe,
  CameraComponentComponent,
  ImageCropperComponentComponent,
  OpenCameraDialogComponent,
  studentDayAttendancePipe,
  studentDayAttendanceColPipe,
  studentAttendancePPipe,
  studentAttendanceAPipe,
  studentAttendanceTotalAPipe,
  studentAttendanceTotalPPipe,
  studentAttendanceMonthTotalAPipe,
  studentAttendanceYearTotalAPipe,
  InstructorNamePipe,
  StudentNamePipe,
  GenerateRemarkColorCssClassPipe,
  RemarkColorClassNoBackgroundPipe,
  FindItsAttendanceStatPipe,
  FindRevokedPermissionDayPipe,
  IdentifiedSelectionOfPermissibleSessionCardPipe,
  GetFirstLetterProfilePipe,
  FindAttConfirmLogsPipe,
  GenerateAuditDescriptionPipe,
  ExtractFilenameFromStringPipe,
  GetPolicyRemarksAlongWithEmployeeTypePipe,
  GetPolicyRemarksIconPipe,
  FinalJudgementAttendanceRemarksPipe,
  ChunkPipe,
  ConvertDateKeyToDatePipe,
  FilterOutPermissibleRemarksPipe,
  studentAttendanceStatisticTotalAPipe,
  studentAttendanceStatisticTotalPPipe,
  studentAttendanceStatisticYearlyTotalAPipe,
  FilterEmployeeHourPipe,
  replaceStorageIdPipe,
  FilterEmployeeSessionPipe,
  FilterEmployeeSchedulePipe,
  EmployeeScheduleAttRemarkPipe,
  FilterScheduleHolidayPipe,
  GradeColorPipe,
  imageDisplaySizePipe,
  MonthNamePipe,
  InstructorMonthlyAttendancePipePipe
];
const modules = [
  LightboxModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  CommonModule,
  MaterialModule,
  MobxAngularModule,
  NguCarouselModule,
  InfiniteScrollModule,
  MatSelectFilterModule,
  FileUploadModule,
  HttpClientModule,
  QuillModule,
  KatexModule,
  TimepickerModule,
  NgxChartsModule,
  NgApexchartsModule,
  WebcamModule,
  ImageCropperModule,
  MomentDateModule,
  LazyLoadImageModule,
  NgPipesModule,
  VirtualScrollerModule,
];


@NgModule({
  declarations: [
    ...components,
    RejectRequestComponent,
  ],
  imports: [
    ...modules,
  ],
  exports: [
    ...components,
    ...modules,
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class SharedModule { }

