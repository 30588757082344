import { Pipe, PipeTransform } from '@angular/core';
import { groupByData } from '../services/mapping.service';

@Pipe({
  name: 'getPolicyRemarksAlongWithEmployeeType'
})
export class GetPolicyRemarksAlongWithEmployeeTypePipe implements PipeTransform {

  transform(employeeTypeRef: Array<any>, policyRemarks: Array<any>): unknown {
    if(!employeeTypeRef) return null;
    let grouppedPolicyRemarks = groupByData(policyRemarks, 'userType', 'userType.key');
    const result = grouppedPolicyRemarks.filter((item) => {
      return employeeTypeRef.some(e => {
        return e.key === item.userType.key;
      })
    });
    return result;
  }

}
