<!-- <span *ngIf="!isDate">{{data?.province?.khmer_name}}</span> -->
<!-- <span *ngIf="isDate">{{data?.toDate() | dateOnly}}</span> -->

<ng-container *ngIf="school?.isNGS else elseNotNgs">

    <ng-container *ngIf="field === 'khmer_last_name'">
        {{data?.khmer_last_name?data?.khmer_last_name:data?.last_name||'N/A'}}
    </ng-container>


    <ng-container *ngIf="field === 'khmer_first_name'">
        {{data?.khmer_first_name?data?.khmer_first_name:data?.first_name||'N/A'}}
    </ng-container>

    <ng-container *ngIf="field === 'gender'">
        <ng-container *ngIf="data?.gender?.key === 1; else genderTemplate">
            ប្រុស
        </ng-container>
        <ng-template #genderTemplate>
            ស្រី
        </ng-template>
    </ng-container>     
  

</ng-container>
<ng-template #elseNotNgs>
    <ng-container *ngIf="field === 'khmer_last_name'">
        {{data?.last_name?data?.last_name:data?.khmer_last_name||'N/A'}}
    </ng-container>


    <ng-container *ngIf="field === 'khmer_first_name'">
        {{data?.first_name?data?.first_name:data?.khmer_first_name||'N/A'}}
    </ng-container>


    <ng-container *ngIf="field === 'gender'">
        <ng-container *ngIf="data?.gender?.key === 1; else genderTemplate">
            M
        </ng-container>
        <ng-template #genderTemplate>
            F
        </ng-template>
    </ng-container>   
</ng-template>
