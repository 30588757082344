import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Cashier } from 'src/app/core/stores/cashier.store';
import { Environment } from 'src/app/core/stores/environment';
import {Location} from '@angular/common'
@Component({
  selector: 'app-cashier-panel',
  templateUrl: './cashier-panel.component.html',
  styleUrls: ['./cashier-panel.component.scss']
})
export class CashierPanelComponent implements OnInit {
  cstab;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public store: Cashier,
    public env: Environment,
    private location: Location
  ) { }

  ngOnInit() {
    this.route.params.forEach(param => {
      // this.cstab = this.tabs.map(f => ({ path: '/payment-listing/' + param.id + f.slabel, label: f.label, icon: f.icon }));
      this.store.fetchCashierDoc(param.id, user => { })
    })
  }
  _goBack() {
    // this.router.navigate(['/']);
    this.location.back()
  }
}
