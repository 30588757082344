import { Pipe, PipeTransform } from '@angular/core';
import { sum } from '../services/convert.service';

@Pipe({
  name: 'filterData'
})
export class FilterDataPipe implements PipeTransform {

  transform(value: any[], key: any, headerKey?: boolean,field?:any): unknown {
    if (value) {
      if (headerKey) {
        const items= value.filter(m => m.headerKey === key)
        if(field) return sum(items,field)
        return items
      }
      return value.filter(m => m.programKey === key)
    }
    return null;
  }



}
