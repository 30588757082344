import { Component, Input, OnInit } from '@angular/core';
import { TEXT_TYPES } from 'src/app/core/dummy/app';

@Component({
  selector: 'app-text-display',
  templateUrl: './text-display.component.html',
  styleUrls: ['./text-display.component.scss']
})
export class TextDisplayComponent implements OnInit {
  @Input() mathType:any;
  @Input() text:any;

  TEXT_TYPES:any=TEXT_TYPES;
  constructor() { }

  ngOnInit() {
  }

}
