import { Pipe, PipeTransform } from '@angular/core';
import { SCHOOL } from 'src/app/core/dummy/config';
import { fileNameAddSize, ImageSizeType, UrlToPath } from '../services/fire-storage.service';

@Pipe({
  name: 'extractFilenameFromString'
})
export class ExtractFilenameFromStringPipe implements PipeTransform {
  transform(fileName: string): unknown {
    return fileName.substring(fileName.lastIndexOf('.') + 1);
  }
}

@Pipe({
  name: 'replaceStorageId'
})
export class replaceStorageIdPipe implements PipeTransform {
  transform(url: any): any {
    if (url && SCHOOL.key === 'ngs' && !SCHOOL.isPSIS) {
      const str = 'ngsclouddev.appspot.com';
      const replaceUrl = url.replace(/\/b\/.*\/o\//, `/b/${str}/o/`);
      return replaceUrl
    } else {
      return url;
    }

  }
}



// export function replaceStorageId(url) {
//   if (url && SCHOOL.key === 'ngs' && !SCHOOL.isPSIS) {
//     const str = 'ngsclouddev.appspot.com';
//     const replaceUrl = url.replace(/\/b\/.*\/o\//, `/b/${str}/o/`);
//     return replaceUrl
//   } else {
//     return url;
//   }
// }

 @Pipe({
  name: 'imageDisplaySize'
})
export class imageDisplaySizePipe implements PipeTransform {
  transform(uri: string, sizeX: ImageSizeType, sizeY?: ImageSizeType): any {
    const [pathUrl, token, rawPath] = UrlToPath(uri)
    if (sizeX === '525') {
      sizeY = '650'
    }
    const [path, name] = fileNameAddSize(pathUrl, sizeX, sizeY ? sizeY : sizeX)
    const pathName = sizeX ? `${path ? `${path}/` : ''}thumbs/${name}` : `${path ? `${path}/` : ''}${name}`
    const ref = encodeURIComponent(pathName)
    return { uri: `${rawPath}${ref}?${token}` }

  }
}

