import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterStudentLevel'
})
export class FilterStudentLevelPipe implements PipeTransform {

  transform(value: any[], levelKey: any): unknown {
    if (value) {
      const total = value.filter(m => m.trainingGrade && m.trainingGrade.key === levelKey).length;
      return total <= 0 ? null : total;
    }
    return null;
  }

}
