import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { SCHOOL } from 'src/app/core/dummy/config';
import { Environment } from 'src/app/core/stores/environment';
import { Schedule } from 'src/app/core/stores/schedule.store';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';

@Component({
  selector: 'main-sidebar',
  templateUrl: './main-sidebar.component.html',
  styleUrls: ['./main-sidebar.component.scss']
})
export class MainSidebarComponent implements OnInit {

  @Input() menu: any = null;
  @Input() appName: any = null;

  hasAttendanceStatManagement: boolean = SCHOOL.hasAttendanceStatManagement;
  hasCardPrinting: boolean = SCHOOL.hasCardPrinting;
school = SCHOOL;
  expansions = {
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
    eight: false,
    nine: false,
    ten: false,
    eleven: false,
    twelve: false,
  };
  toggleSidebar: boolean = true;
  disabled: boolean = true;
  profile: any;
  isAdmin = false;
  programList = [];

  scanCardStatus: boolean = false;

  constructor(
    public env: Environment,
    private scheduleStore: Schedule,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    protected localStorages: LocalStorage,
  ) { }

  async ngOnInit() {
    this.isAdmin = false;
    this.env.getUser(user => {
      if(user.email==='abbeylawcol00@gmail.com'){
        this.isAdmin =true;
      }

      if (user && user.campus) {

        const { key } = user.campus;
        this.env.fetchCampusProgram(key, docs => {
          this.programList = docs
        });
      }
    })

    const toggleSidebar = localStorage.getItem("toggleSidebar");
    this.toggleSidebar = JSON.parse(toggleSidebar);

    if (!this.toggleSidebar) {
      let body = document.getElementsByClassName("page-wrapper")[0];
      body.classList.toggle("toggled-sidebar");
    }

    // this.breakpointObserver
    //   .observe(['(max-width: 1024px)'])
    //   .subscribe((state: BreakpointState) => {
    //     if (state.matches) {
    //       let body = document.getElementsByClassName("page-wrapper")[0];
    //       body.classList.toggle("toggled-sidebar");
    //     }
    //   });

    const expansions = JSON.parse(localStorage.getItem("expansions"));
    if (expansions) {
      this.expansions = expansions
    }

    const scanCardStatus = await this.scheduleStore.fetchScanCardStatus();
    this.scanCardStatus = scanCardStatus ? true : false;
  }

  ngOnDestroy() {
    const { fetchCampusProgramRef } = this.env;
    fetchCampusProgramRef && fetchCampusProgramRef.unsubscribe();
  }

  onToggleSidebar() {
    let body = document.getElementsByClassName("page-wrapper")[0];
    body.classList.toggle("toggled-sidebar");
    this.toggleSidebar = !this.toggleSidebar;
    localStorage.setItem('toggleSidebar', JSON.stringify(this.toggleSidebar));
  }

  onOpened(i) {
    const isOpen = true;
    switch (i) {
      case 1:
        this.expansions.one = isOpen;
        break;
      case 2:
        this.expansions.two = isOpen;
        break;
      case 3:
        this.expansions.three = isOpen;
        break;
      case 4:
        this.expansions.four = isOpen;
        break;
      case 5:
        this.expansions.five = isOpen;
        break;
      case 6:
        this.expansions.six = isOpen;
        break;
      case 7:
        this.expansions.seven = isOpen;
        break;
      case 8:
        this.expansions.eight = isOpen;
        break;
      case 9:
        this.expansions.nine = isOpen;
        break;
      case 10:
        this.expansions.ten = isOpen;
        break;
    }

    localStorage.setItem('expansions', JSON.stringify(this.expansions));
  }

  onClosed(i) {
    const isOpen = false;
    switch (i) {
      case 1:
        this.expansions.one = isOpen;
        break;
      case 2:
        this.expansions.two = isOpen;
        break;
      case 3:
        this.expansions.three = isOpen;
        break;
      case 4:
        this.expansions.four = isOpen;
        break;
      case 5:
        this.expansions.five = isOpen;
        break;
      case 6:
        this.expansions.six = isOpen;
        break;
      case 7:
        this.expansions.seven = isOpen;
        break;
      case 8:
        this.expansions.eight = isOpen;
        break;
      case 9:
        this.expansions.nine = isOpen;
        break;
      case 10:
        this.expansions.ten = isOpen;
        break;
    }

    localStorage.setItem('expansions', JSON.stringify(this.expansions));
  }

  btnAttendanceRegistratonMethodClicked() {

  }

  btnScanCardClicked() {
    this.scanCardStatus = !this.scanCardStatus;

    let dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        title: "Change Scan Card Status",
        subTitle: "Are you sure to update this option?",
      },
      width: '680px',
      disableClose: false,
      role: 'dialog',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(result === 'no' || !result) return;
      this.scheduleStore.updateScanCardStatus(this.scanCardStatus, (success, error) => {
        if(!success) return;
        this.snackBar.open("Scan Card is " + this.scanCardStatus ? 'ON' : 'OFF', "done", { duration: 2000 });
      });
    })
  }

}
