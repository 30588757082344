import { MappingService } from '../services/mapping.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterScholarship'
})
export class FilterScholarshipPipe implements PipeTransform {

  transform(value: Array<any>, args?: any): any {
    return MappingService.filter(value, "scholarship_type.type.key", args);
  }

}
