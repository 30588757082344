<div class="dialog-wrapper">
  <div class="dialog-header">
    <div class="title" style="color: rgb(52, 118, 208);">
      តារាងកាលវិភាគសម្រាប់គ្រូ
    </div>
    <button mat-button class="enbutton primary" (click)="exportToExcel()" style="flex: none;">
      <span>Excel</span>
    </button>

    <div class="flex1"></div>
    <a mat-dialog-close class="close-dialog" (click)="onClose()">
      <i class="material-icons">&#xE14C;</i>
    </a>
  </div>

  <app-spinner *ngIf="loading"></app-spinner>

  <div class="dialog-content" #table *ngIf="!loading">
    <div class="form-content page" #PRINT_TABLE id="PRINT_TABLE">
      <div *ngIf="this.data.date.length>0" class="cs-container most-absent-students-report-container"
        style="height: 480px;">

        <p style="font-size: 18px; margin-bottom: 0;">
          Code:
          <b> {{data?.item?.employee?.code}} </b> -

          Name:
          <b> {{data?.item?.employee?.khmer_full_name ?
            data?.item?.employee?.khmer_full_name :
            data?.item?.employee?.full_name}}</b>
        </p>

        <div class="scrollable-table" style="padding: 0px">
          <table class="table-wrapper" #table>
            <thead class="table-header">
              <tr class="table-row">
                <th class="table-col center" style="white-space: nowrap; background-color: #E2F0D9;" rowspan="2">
                  No</th>
                <th class="table-col center" style="white-space: nowrap; background-color: #E2F0D9;" rowspan="2">
                  Session</th>

                <th class="table-col center htmlToExcelText" style="white-space: nowrap; background-color: #E2F0D9; "
                  *ngFor="let item of data.date"
                  [ngStyle]="{'color': (data?.holiday|filterScheduleHoliday:item?.dateKey) ? '#3476d0' :  (item?.dayKey===0 || item?.dayKey===6)?'red':null,
                'background-color': (data?.holiday|filterScheduleHoliday:item?.dateKey) ? 'rgba(51, 51, 51, 0.1)' :  null }">
                  <span class="htmlToExcelText">
                    {{item.dayNumber+'/'+item?.monthKey}}
                  </span>
                </th>
              </tr>

              <tr class="table-row">
                <ng-container *ngFor="let item of data.date">
                  <th class="table-col center" style="white-space: nowrap;"
                    [ngStyle]="{'color': (data?.holiday|filterScheduleHoliday:item?.dateKey) ? '#3476d0' :  (item?.dayKey===0 || item?.dayKey===6)?'red':null,
                  'background-color': (data?.holiday|filterScheduleHoliday:item?.dateKey) ? 'rgba(51, 51, 51, 0.1)' :  null }">
                    {{item?.dayName.substring(0, 3)}}
                  </th>
                </ng-container>
              </tr>
            </thead>

            <tbody class="table-body attendanceSheet">
              <tr *ngFor="let group of sessionGroup,let i = index">
                <td class="table-col" style="white-space: nowrap;">
                  {{ i+1 }}
                </td>
                <td class="table-col" style="white-space: nowrap;">
                  {{group?.session?.fromHours}} - {{group?.session?.toHours}}
                  <!-- <p class="sub-text"> {{group?.session?.key}}</p> -->
                  <!-- <p class="sub-text"> {{group?.session?.sessionDay[0]?.key}} - {{group?.session?.sessionDay[0]?.name}} </p> -->
                </td>

                <ng-container *ngFor="let date of data.date">

                  <!-- <td [rowSpan]="sessionGroup.length"
                    *ngIf="(data?.holiday|filterScheduleHoliday:date?.dateKey) && (sessionGroup.length>0) && i===0"
                    [ngStyle]="{'color': (data?.holiday|filterScheduleHoliday:date?.dateKey) ? '#3476d0' :  null,
            'background-color': (data?.holiday|filterScheduleHoliday:date?.dateKey) ? 'rgba(51, 51, 51, 0.1)' :  null }"
                    class="block attendanceSheetHolidayCell">
                    {{(data?.holiday|filterScheduleHoliday:date?.dateKey).khName}}
                  </td> -->


                  <td [rowSpan]="sessionGroup.length"
                    *ngIf="(data?.holiday|filterScheduleHoliday:date?.dateKey) && (sessionGroup.length<=3) && i===0"
                    [ngStyle]="{'color': (data?.holiday|filterScheduleHoliday:date?.dateKey) ? '#3476d0' :  null,
            'background-color': (data?.holiday|filterScheduleHoliday:date?.dateKey) ? 'rgba(51, 51, 51, 0.1)' :  null }"
                    class="block attendanceSheetHolidayCell">
                    {{(data?.holiday|filterScheduleHoliday:date?.dateKey).khName}}
                  </td>

                  <td *ngIf="(sessionGroup.length>3) && (data?.holiday|filterScheduleHoliday:date?.dateKey)" [ngStyle]="{'color': (data?.holiday|filterScheduleHoliday:date?.dateKey) ? '#3476d0' :  null,
          'background-color': (data?.holiday|filterScheduleHoliday:date?.dateKey) ? 'rgba(51, 51, 51, 0.1)' :  null }"
                    class="block attendanceSheetHolidayCell">
                    <div class="rotateAtt">
                      <ng-container *ngIf="(i>=sessionGroup?.length/2 -1) && (i<sessionGroup?.length/2)">
                        {{(data?.holiday|filterScheduleHoliday:date?.dateKey).khName}}
                      </ng-container>
                    </div>
                  </td>

                  <td class="table-col center" style="white-space: nowrap;"
                    *ngIf="!(data?.holiday|filterScheduleHoliday:date?.dateKey)">
                    <ng-container *ngIf="(group?.session|filterEmployeeSchedule:session:date)">

                      <p class="sub-text" style="color:#0081C9">
                        {{(group?.session|filterEmployeeSchedule:session:date)?.batch?.name}}
                      </p>
                      <p class="sub-text" style="color:#B04759">
                        {{(group?.session|filterEmployeeSchedule:session:date)?.schedule_subject?.subject?.name}}
                      </p>
                      <p class="sub-text" [ngStyle]="{'color':(group?.session|filterEmployeeSession:this.data.item.attendanceLog:date)?.remark==='OK'
                      || (group?.session|filterEmployeeSession:this.data.item.attendanceLog:date)?.remark==='L'
                      || (group?.session|filterEmployeeSession:this.data.item.attendanceLog:date)?.remark==='M'

                        ?'null'
                        :'#D21312'}">
                        {{(group?.session|filterEmployeeSession:this.data.item.attendanceLog:date)?.remark}}
                        <button class="tea-attSheetinfo hideInExcel" mat-icon-button
                          *ngIf="(group?.session|filterEmployeeSession:this.data.item.attendanceLog:date)?.note"
                          [matTooltip]="(group?.session|filterEmployeeSession:this.data.item.attendanceLog:date)?.note"
                          aria-label="Example icon-button with a menu">
                          <mat-icon style="font-size: 18px;
                        line-height: 20px;
                        height: 22px;
                        width: 22px;color:#444">info</mat-icon>
                        </button>
                      </p>
                      <p class="sub-text" style="color:#ED2B2A">
                        {{(group?.session|filterEmployeeSession:this.data.item.attendanceLog:date)?.adminConfirm===true?(group?.session|filterEmployeeSession:this.data.item.attendanceLog:date)?.confirmBy:null}}
                      </p>

                      <!-- <p class="sub-text" *ngIf="(group?.session|filterEmployeeSession:this.data.item.attendanceLog:date)?.note!==null" class="sub-text" >
                       (note: {{(group?.session|filterEmployeeSession:this.data.item.attendanceLog:date)?.note}})
                      </p> -->
                    </ng-container>
                  </td>
                </ng-container>

              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>

  <div class="dialog-footer">
    <div class="flex1"></div>
    <button mat-dialog-close type="button" class="enbutton cancel m-r16" mat-button (click)="onClose()">Close</button>
    <!-- <button mat-button class="enbutton primary" (click)="onPrint()" style="flex: none;">
      <i class="material-icons">print</i>
      <span>បោះពុម្ព</span>
    </button> -->
  </div>
</div>
