import { Pipe, PipeTransform } from '@angular/core';
import { sum } from '../services/convert.service';

@Pipe({
  name: 'sumTestingSection'
})
export class SumTestingSectionPipe implements PipeTransform {

  transform(value: any, answers: any[], ...args: unknown[]): unknown {
    if (value) {
      const sections = answers.filter(m => m.sectionKey === value.key && typeof (m.score) === 'number');
      return sections.length > 0 ? `(Score: ${sum(sections, "score")}pt)` : null
    }
    return null;
  }

}
