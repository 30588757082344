import { DataService } from './../services/data.service';
import { observable, computed, action, autorun, toJS } from 'mobx';
import { Injectable } from '@angular/core';
import { MappingService } from 'src/app/shared/services/mapping.service';

@Injectable({providedIn:'root'})
export class Campus {
  @observable data: any = [];
  @observable loading = false;
  @observable selectedCampus: any = null;

  constructor(private ds: DataService) {
  }

  @action
  fetchCampus() {
    this.loading = true;
    this.ds
      .campusRef()
      .valueChanges()
      .subscribe(docs => {
        const cp = MappingService.orderBy(docs,'orderNo')
        this.data = cp;
        if (docs.length > 0) {
          if (!this.selectedCampus) {
            this.selectedCampus = docs[0];
          }
        }
        this.loading = false;
      });
  }

  @action
  fetchCampusCalback(callback) {
    this.loading = true;
    this.ds
      .campusRef()
      .valueChanges()
      .subscribe(docs => {
        const cp = MappingService.orderBy(docs,'order')
        this.data = cp;
        callback(cp);
        if (docs.length > 0) {
          if (!this.selectedCampus) {
            this.selectedCampus = docs[0];
          }
        }
        this.loading = false;
      });
  }

  @action
  fetchCampusDoc(doc, callback) {
    this.loading = true;
    this.ds
      .campusDocRef(doc)
      .valueChanges()
      .subscribe(doc => {
        this.selectedCampus = doc;
        callback(doc)
        this.loading = false;
      });
  }
}
