import { Pipe, PipeTransform } from '@angular/core';
import { MappingService } from '../services/mapping.service';

@Pipe({
  name: 'sumTuitionFee'
})
export class SumTuitionFeePipe implements PipeTransform {

  transform(value: any, data?: Array<any>, termKey?: string, invoiceKey?: string, sumField?: any): any {
    const filter = data.filter(m => m.issue_year.key === termKey && m.invoice_type.key === invoiceKey);
    const total = MappingService.sum(filter.slice(), sumField);
    return total;
  }

}
