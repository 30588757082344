import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'finalJudgementAttendanceRemarks'
})
export class FinalJudgementAttendanceRemarksPipe implements PipeTransform {

  transform(attendancesByShift: Array<any>) {
    let finalRemark: any = null;

    if(attendancesByShift.length >= 0) {
      const ATTENDANCES_ENUM = [
        { name: 'P', value:"5" },
        { name: 'M', value:"4" },
        { name: 'A', value:"3" },
        { name: 'L', value:"2" },
        { name: 'OK', value:"1" }
      ];

      finalRemark = attendancesByShift[0].remark;
      attendancesByShift.forEach((item) => {
        const leftOperand = ATTENDANCES_ENUM.find((att) => att.name === item.remark?.name ) || ATTENDANCES_ENUM[4];
        const rightOperand = ATTENDANCES_ENUM.find((att) => att.name === finalRemark?.name ) || ATTENDANCES_ENUM[4];
        if(leftOperand.value > rightOperand.value) finalRemark = item.remark;
      });
    } else finalRemark = attendancesByShift[0].remark;

    return finalRemark;
  }

}
