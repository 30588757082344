import { DataService } from './../services/data.service';
import { observable, computed, action, autorun, toJS } from 'mobx';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  paymentStatus,
  recordStatus,
  REQUEST_STATUS,
  StatusObj,
} from '../dummy/stauts';
import {
  MappingService,
  numberToDate,
  orderBy,
  pushToArray,
  pushToObject,
  attendantRemarkObj,
  studentObj,
  toDateKey,
  userObj,
} from 'src/app/shared/services/mapping.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireFunctions } from '@angular/fire/functions';
import { combineLatest, Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import 'firebase/functions';
import { SCHOOL } from '../dummy/config';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class InstituteEnrollment {
  @observable public student = null;
  @observable public selectedAdmission = null;
  @observable public admissions: any[] = [];
  @observable public program = null;
  @observable public scholarships: any[] = [];
  @observable data: any[] = [];
  @observable receipts: any[] = [];
  @observable unpaidReceipts: any[] = [];
  @observable dropStudents: any[] = [];
  @observable schedules: any[] = [];
  @observable loading = false;
  @observable empty = false;
  @observable public process = false;
  @observable public studentBatch = null;
  @observable public invoiceDetail = null;
  @observable public schedulesBatch = null;
  @observable public selectedBatch = null;

  selectedFiles: any;
  uploadFolder;
  uploads: any[];
  allPercentage: Observable<any>;
  files: Observable<any>;

  constructor(
    private afs: AngularFirestore,
    private ds: DataService,
    private router: Router,
    private http: HttpClient,
    private storage: AngularFireStorage,
    private aff: AngularFireFunctions
  ) { }

  @action
  async fetchPolicyRemark(schoolKey, callback) {
    const policyRemark = await this.ds
      .storeDocRef(schoolKey)
      .collection('policy_remarks')
      .get()
      .toPromise();
    const data = pushToArray(policyRemark);
    callback(data?.filter((d) => d.name === 'M' || d.name === 'P'));
  }

  @action
  async fetchPolicyRemark_withoutPermissibleRemarks(schoolKey, callback) {
    const policyRemark = await this.ds
      .storeDocRef(schoolKey)
      .collection('policy_remarks')
      .get()
      .toPromise();
    const data = pushToArray(policyRemark);
    callback(data?.filter((d) => d.name !== 'M' && d.name !== 'P'));
  }

  @action
  async getInstEmpPolicyRemarks(userType, callback) {
    const policyRemarks = pushToArray(
      await this.ds
        .storeRef()
        .collection('inst_emp_policy_remarks', (ref) =>
          ref.where('userType.key', '==', userType.key)
        )
        .get()
        .toPromise()
    );
    callback(policyRemarks?.filter((d) => d.name === 'M' || d.name === 'P'));
  }
  @action
  async revokePermission(
    student: any,
    policyRemark: any,
    date: any,
    permissions: any,
    user,
    callback?: (isSuccess, error) => void
  ) {
    try {
      this.process = true;
      const studentKey = student.key;
      const dateKey = toDateKey(moment(date).toDate());
      const remarks = pushToArray(
        await this.ds.storeRef().collection('policy_remarks').get().toPromise()
      );
      const permissibleRemarks = remarks.filter((remark) =>
        ['M', 'P'].includes(remark.name)
      );

      permissions = await Promise.all(
        permissions.map(async (permissionData) => {
          const revokePermissionRecord = {
            key: this.ds.createId(),
            create_date: new Date(),
            create_date_key: toDateKey(new Date()),
            create_by: userObj(user),
            create_by_key: user.key,

            is_delete: false,
            status: StatusObj.ACTIVE,

            studentKey: studentKey,
            student: studentObj(student),
            permissionKey: permissionData.key,
            remark: attendantRemarkObj(policyRemark),
            remarkKey: policyRemark.key,
            revokeDate: moment(date).toDate(),
            revokeDateKey: dateKey,
          };

          if (
            permissionData?.relatedTimeAttendances &&
            permissionData.relatedTimeAttendances.length > 0
          ) {
            await this.ds
              .storeRef()
              .collection('revoke_permission_records')
              .doc(revokePermissionRecord.key)
              .set(revokePermissionRecord, { merge: true });

            permissionData.relatedTimeAttendances = await Promise.all(
              permissionData.relatedTimeAttendances.map(
                async (timeAttendance) => {
                  timeAttendance.itsMovements = await Promise.all(
                    timeAttendance.itsMovements.map(async (itsMovement, i) => {
                      const isOverwrited = permissibleRemarks.some(
                        (e) => e.key === itsMovement.remark.key
                      );
                      if (itsMovement.isPrioritizedMovement && isOverwrited) {
                        await this.ds
                          .storeRef()
                          .collection('student_attendance_movement')
                          .doc(itsMovement.key)
                          .delete();
                        await this.ds
                          .studentsRef()
                          .doc(studentKey)
                          .collection('student_attendance_movement')
                          .doc(itsMovement.key)
                          .delete();
                        await new Promise((resolve) => {
                          setTimeout(() => {
                            resolve(i);
                          }, 500);
                        });
                        return null;
                      }
                      return itsMovement;
                    })
                  );

                  timeAttendance.itsMovements =
                    timeAttendance.itsMovements.filter((item) => item !== null);
                  timeAttendance.itsMovements = await Promise.all(
                    timeAttendance.itsMovements.map(async (itsMovement, i) => {
                      const isOverwrited = permissibleRemarks.some(
                        (e) => e.key === itsMovement.remark.key
                      );
                      if (isOverwrited) {
                        await this.ds
                          .storeRef()
                          .collection('student_attendance_movement')
                          .doc(itsMovement.key)
                          .update({
                            remark: attendantRemarkObj(policyRemark),
                            remarkKey: policyRemark.key,
                          });
                        await this.ds
                          .studentsRef()
                          .doc(studentKey)
                          .collection('student_attendance_movement')
                          .doc(itsMovement.key)
                          .update({
                            remark: attendantRemarkObj(policyRemark),
                            remarkKey: policyRemark.key,
                          });
                        await new Promise((resolve) => {
                          setTimeout(() => {
                            resolve(i);
                          }, 500);
                        });
                        return {
                          ...itsMovement,
                          remark: isOverwrited
                            ? attendantRemarkObj(policyRemark)
                            : itsMovement.remark,
                        };
                      }
                      return itsMovement;
                    })
                  );

                  const isOverwrited_1 = permissibleRemarks.includes(
                    timeAttendance.remark.key
                  );
                  return {
                    ...timeAttendance,
                    remark: isOverwrited_1
                      ? attendantRemarkObj(policyRemark)
                      : timeAttendance.remark,
                  };
                }
              )
            );
          }

          return permissionData;
        })
      );

      callback && callback(true, null);
    } catch (error) {
      console.log(error);
      callback && callback(false, error);
    } finally {
      this.process = false;
    }
  }

  @action
  async revokePermissionApi(data, callback) {
    try {
      this.process = true;
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      var raw = JSON.stringify({
        revoke_date: data.revoke_date,
        revoke_date_Key: data.revoke_date_key,
        user: userObj(data.user),
        remark: attendantRemarkObj(data.remark),
        permission: data.permissions,
        student: studentObj(data.student),
        revoke_reason: data.revoke_reason,
      }) as string;

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      let url = '';
      switch (SCHOOL.key) {
        case 'ngs':
          url =
            'https://asia-east2-ngsclouddev.cloudfunctions.net/onRevokeStudentPermission';
          break;
        case 'sovannaphumi_school':
          url =
            'https://us-central1-spscloud-e9e6a.cloudfunctions.net/onRevokeStudentPermission';
          break;
        case 'ngs-preakleap':
          url =
            'https://asia-east2-ngs-preakleap.cloudfunctions.net/onRevokeStudentPermission';
          break;
        case 'ngs-prek-anchanh':
          url =
            'https://asia-east2-ngs-prek-anchanh.cloudfunctions.net/onRevokeStudentPermission';
          break;
        case 'ngs_kampong_cham':
          url =
            'https://asia-east2-ngs-kampong-cham.cloudfunctions.net/onRevokeStudentPermission';
          break;
        case 'ngs_peam_chikang':
          url =
            'https://asia-east2-ngs-peam-chi-kang.cloudfunctions.net/onRevokeStudentPermission';
          break;
        case 'manner_school':
          url =
            'https://asia-east2-manner-1f3f8.cloudfunctions.net/onRevokeStudentPermission';
          break;
        default:
          break;
      }

      fetch(url, requestOptions as any)
        .then((response) => response.text())
        .then((result) => {
          callback(true, null);
          this.process = false;
          return;
        })
        .catch((error) => console.log('error', error));
    } catch (error) {
      callback(false, error);
      this.process = false;
    }
  }
  @action
  async addApprovePermissions(permission, user: any, remark, callback) {
    try {
      this.process = true;

      delete remark.storeRef;
      delete remark.created_byRef;
      delete remark.updated_byRef;
      delete permission.studentRef;
      delete permission.created_byRef;

      const userData = {
        key: user.key,
        schoolKey: user.schoolKey,
        school: user.school,
        campusKey: user.campusKey,
        campus: user.campus,
        name: user.name,
        email: user.email,
        displayName: user.displayName,
      };

      const permissions = [
        {
          permissionKey: permission.key,
          user: userData,
          remark: remark,
          permission: permission,
        },
      ];

      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      // const raw = JSON.parse(JSON.stringify({
      //   permissions: permissions,
      //     schoolKey: permission.schoolKey,
      //     campusKey: permission.campusKey
      // }));

      var raw = JSON.stringify({
        permissions: permissions,
        schoolKey: permission.schoolKey,
        campusKey: permission.campusKey,
      }) as string;

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      let url = '';
      switch (SCHOOL.key) {
        case 'ngs':
          url =
            'https://asia-east2-ngsclouddev.cloudfunctions.net/onApprovedPermissions';
          break;
        case 'sovannaphumi_school':
          url =
            'https://us-central1-spscloud-e9e6a.cloudfunctions.net/onApprovedPermissions';
          break;
        case 'ngs-preakleap':
          url =
            'https://asia-east2-ngs-preakleap.cloudfunctions.net/onApprovedPermissions';
          break;
        case 'ngs-prek-anchanh':
          url =
            'https://asia-east2-ngs-prek-anchanh.cloudfunctions.net/onApprovedPermissions';
          break;
        case 'ngs_kampong_cham':
          url =
            'https://asia-east2-ngs-kampong-cham.cloudfunctions.net/onApprovedPermissions';
          break;
        case 'ngs_peam_chikang':
          url =
            'https://asia-east2-ngs-peam-chi-kang.cloudfunctions.net/onApprovedPermissions';
          break;
        case 'manner_school':
          url =
            'https://asia-east2-manner-1f3f8.cloudfunctions.net/onApprovedPermissions';
          break;
        default:
          break;
      }

      fetch(url, requestOptions as any)
        .then((response) => response.text())
        .then((result) => {
          callback(true, null);
          this.process = false;
          return;
        })
        .catch((error) => console.log('error', error));
    } catch (error) {
      callback(false, error);
      this.process = false;
    }
  }

  @action
  async rejectPermissions(permission, callback) {
    const batch = this.ds.batch();
    this.process = true;
    const { studentKey, schoolKey, campusKey } = permission;

    const spRef = this.ds
      .studentFireRef()
      .doc(studentKey)
      .collection('permissions')
      .doc(permission.key);
    const spsRef = this.ds
      .schoolFireRef()
      .doc(schoolKey)
      .collection('permissions')
      .doc(permission.key);
    const spscRef = this.ds
      .schoolFireRef()
      .doc(schoolKey)
      .collection('campus')
      .doc(campusKey)
      .collection('permissions')
      .doc(permission.key);

    batch.update(spRef, permission);
    batch.update(spsRef, permission);
    batch.update(spscRef, permission);
    batch
      .commit()
      .then(() => {
        callback(true, null);
        this.process = false;
      })
      .catch((error) => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  deletePermissionImg(form, file, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { studentKey, schoolKey, campusKey, attachment } = form;
    const spRef = this.ds
      .studentFireRef()
      .doc(studentKey)
      .collection('permissions')
      .doc(form.key);
    const spsRef = this.ds
      .schoolFireRef()
      .doc(schoolKey)
      .collection('permissions')
      .doc(form.key);
    const spscRef = this.ds
      .schoolFireRef()
      .doc(schoolKey)
      .collection('campus')
      .doc(campusKey)
      .collection('permissions')
      .doc(form.key);

    const attachmentData = attachment.filter((m) => m.key !== file.key);

    batch.update(spRef, { attachment: attachmentData });
    batch.update(spsRef, { attachment: attachmentData });
    batch.update(spscRef, { attachment: attachmentData });

    this.deleteFile('student_documents', file.fileName);

    batch
      .commit()
      .then(() => {
        this.process = false;
        callback(true, null);
      })
      .catch((error) => {
        this.process = false;
        callback(false, error);
      });
  }

  @action
  async addRequestPermissionStudent(form: any, filelist, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { attachment } = form;
    const spRef = this.ds.requestPermissionStudent().doc(form.key);

    const allPercentage: Observable<number>[] = [];
    this.uploads = [];
    let fileData = [];
    for (const file of filelist) {
      const path = `student_documents/${file.name}`;
      const task = this.storage.upload(path, file);
      const _percentage$ = task.percentageChanges();
      allPercentage.push(_percentage$);
      const uploadTrack = {
        fileName: file.name,
        percentage: _percentage$,
      };
      this.uploads.push(uploadTrack);
      const _t = await task.then((f) => {
        return f.ref.getDownloadURL().then((url) => {
          const key = this.ds.createId();
          const data = {
            fileName: f.metadata.name,
            fileType: f.metadata.contentType,
            fileUrl: url,
            key: key,
          };
          return fileData.push(data);
        });
      });
    }
    this.allPercentage = combineLatest(allPercentage).pipe(
      map((percentages) => {
        let result = 0;
        for (const percentage of percentages) {
          result = result + percentage;
        }
        return result / percentages.length;
      })
    );
    const files = attachment ? fileData.concat(attachment) : fileData;
    const fdata = {
      ...form,
      attachment: files,
    };

    if (fdata) batch.set(spRef, fdata, { merge: true });

    batch
      .commit()
      .then(() => {
        callback(true, null);
        this.process = false;
      })
      .catch((error) => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  async addPermissions(form: any, filelist, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { studentKey, schoolKey, campusKey, attachment } = form;
    const spRef = this.ds.studentFireRef().doc(studentKey).collection('permissions').doc(form.key);
    const spsRef = this.ds.schoolFireRef().doc(schoolKey).collection('permissions').doc(form.key);
    const spscRef = this.ds.schoolFireRef().doc(schoolKey).collection('campus').doc(campusKey).collection('permissions').doc(form.key);

    const allPercentage: Observable<number>[] = [];
    this.uploads = [];
    let fileData = [];
    for (const file of filelist) {
      const path = `student_documents/${file.name}`;
      const task = this.storage.upload(path, file);
      const _percentage$ = task.percentageChanges();
      allPercentage.push(_percentage$);
      const uploadTrack = {
        fileName: file.name,
        percentage: _percentage$,
      };
      this.uploads.push(uploadTrack);
      const _t = await task.then((f) => {
        return f.ref.getDownloadURL().then((url) => {
          const key = this.ds.createId();
          const data = {
            fileName: f.metadata.name,
            fileType: f.metadata.contentType,
            fileUrl: url,
            key: key,
          };
          return fileData.push(data);
        });
      });
    }
    this.allPercentage = combineLatest(allPercentage).pipe(
      map((percentages) => {
        let result = 0;
        for (const percentage of percentages) {
          result = result + percentage;
        }
        return result / percentages.length;
      })
    );
    const files = attachment ? fileData.concat(attachment) : fileData;
    const fdata = {
      ...form,
      attachment: files,
    };

    if (fdata) batch.set(spRef, fdata, { merge: true });
    batch.set(spsRef, fdata, { merge: true });
    batch.set(spscRef, fdata, { merge: true });

    batch
      .commit()
      .then(() => {
        callback(true, null);
        this.process = false;
      })
      .catch((error) => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  async addInstructorPermission(form: any, filelist, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { employeeKey, schoolKey, attachment } = form;
    const spRef = this.ds
      .employeeFireRef()
      .doc(employeeKey)
      .collection('permissions')
      .doc(form.key);
    const spsRef = this.ds
      .schoolFireRef()
      .doc(schoolKey)
      .collection('employee_permissions')
      .doc(form.key);

    const allPercentage: Observable<number>[] = [];
    this.uploads = [];
    let fileData = [];

    for (const file of filelist) {
      const path = `employee_documents/${file.name}`;
      const task = this.storage.upload(path, file);
      const _percentage$ = task.percentageChanges();
      allPercentage.push(_percentage$);
      const uploadTrack = {
        fileName: file.name,
        percentage: _percentage$,
      };
      this.uploads.push(uploadTrack);
      const _t = await task.then((f) => {
        return f.ref.getDownloadURL().then((url) => {
          const key = this.ds.createId();
          const data = {
            fileName: f.metadata.name,
            fileType: f.metadata.contentType,
            fileUrl: url,
            key: key,
          };
          return fileData.push(data);
        });
      });
    }

    this.allPercentage = combineLatest(allPercentage).pipe(
      map((percentages) => {
        let result = 0;
        for (const percentage of percentages) {
          result = result + percentage;
        }
        return result / percentages.length;
      })
    );

    const files = attachment ? fileData.concat(attachment) : fileData;
    const fdata = { ...form, attachment: files };

    if (fdata) batch.set(spRef, fdata, { merge: true });
    batch.set(spsRef, fdata, { merge: true });

    batch
      .commit()
      .then(() => {
        callback(true, null);
        this.process = false;
      })
      .catch((error) => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  async addInstructorPermission_permissibleSessions(
    permission: any,
    permissibleSessions: Array<any>,
    callback
  ) {
    this.process = true;
    const batch = this.ds.batch();
    const { employeeKey, schoolKey } = permission;

    permissibleSessions.forEach((permissibleSession) => {
      const spRef = this.ds
        .employeeFireRef()
        .doc(employeeKey)
        .collection('permissions')
        .doc(permission.key)
        .collection('permissible_sessions')
        .doc(permissibleSession.key);
      const spsRef = this.ds
        .schoolFireRef()
        .doc(schoolKey)
        .collection('employee_permissions')
        .doc(permission.key)
        .collection('permissible_sessions')
        .doc(permissibleSession.key);
      batch.set(spRef, permissibleSession, { merge: true });
      batch.set(spsRef, permissibleSession, { merge: true });
    });
    batch
      .commit()
      .then(() => {
        callback(true, null);
        this.process = false;
      })
      .catch((error) => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  async rejectInstructorPermission(
    permission: any,
    callback?: (success: boolean, error: any) => void
  ) {
    this.process = true;
    const batch = this.ds.batch();
    const { employeeKey, schoolKey } = permission;

    const spRef = this.ds
      .employeeDocRef(employeeKey)
      .collection('permissions')
      .doc(permission.key).ref;
    const spsRef = this.ds
      .schoolFireRef()
      .doc(schoolKey)
      .collection('employee_permissions')
      .doc(permission.key);

    batch.update(spRef, permission);
    batch.update(spsRef, permission);

    batch
      .commit()
      .then(() => {
        callback(true, null);
        this.process = false;
      })
      .catch((error) => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  async approveInstructorPermission(
    permission: any,
    callback?: (success: boolean, error: any) => void
  ) {
    this.process = true;
    const batch = this.ds.batch();
    const { employeeKey, schoolKey } = permission;

    const spRef = this.ds
      .employeeDocRef(employeeKey)
      .collection('permissions')
      .doc(permission.key).ref;
    const spsRef = this.ds
      .schoolFireRef()
      .doc(schoolKey)
      .collection('employee_permissions')
      .doc(permission.key);

    batch.update(spRef, permission);
    batch.update(spsRef, permission);

    batch
      .commit()
      .then(() => {
        callback(true, null);
        this.process = false;
      })
      .catch((error) => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  async cancelInstructorPermission(
    permission: any,
    callback?: (success: boolean, error: any) => void
  ) {
    this.process = true;
    const batch = this.ds.batch();
    const { employeeKey, schoolKey } = permission;

    const spRef = this.ds
      .employeeDocRef(employeeKey)
      .collection('permissions')
      .doc(permission.key).ref;
    const spsRef = this.ds
      .schoolFireRef()
      .doc(schoolKey)
      .collection('employee_permissions')
      .doc(permission.key);

    batch.update(spRef, permission);
    batch.update(spsRef, permission);

    batch
      .commit()
      .then(() => {
        callback(true, null);
        this.process = false;
      })
      .catch((error) => {
        callback(false, error);
        this.process = false;
      });
  }

  @observable fetchBatchesSchedulesRef: any = null;
  @action
  async fetchBatchesSchedules(programLevelKey, studentKey, callback) {
    this.loading = true;
    const studentDoc = pushToObject(
      await this.ds.studentDocument(studentKey).get().toPromise()
    );
    const batch = studentDoc && studentDoc[programLevelKey];
    this.selectedBatch = batch;
    const batchKey = (batch && studentDoc[programLevelKey].key) || 'NA';
    this.fetchBatchesSchedulesRef = this.ds
      .batchLevelRef()
      .doc(batchKey)
      .collection('schedules', (ref) => ref.orderBy('code'))
      .valueChanges()
      .subscribe((docs) => {
        this.schedules = docs;
        this.loading = false;
        callback(docs);
      });
  }

  @action
  deleteFile(collection, filename) {
    const ext = filename.split('.').pop();
    const name = filename.split('.').slice(0, -1).join('.');
    const fileRef = this.storage.ref(collection + '/' + filename);
    fileRef.delete();
  }

  async checkPayment(studentKey: string, programKey: string, termKey: string) {
    this.loading = true;
    let canEnroll = false;
    this.invoiceDetail = null;
    const scholarshipDoc = await this.ds
      .studentDocument(studentKey)
      .collection('scholarships', (ref) =>
        ref.where('program.key', '==', programKey)
      )
      .get()
      .toPromise();
    const scholarshipData = MappingService.pushToArray(scholarshipDoc);
    if (scholarshipData && scholarshipData.length > 0) {
      const { percentage, loan } = scholarshipData[0];
      if (percentage + loan === 100) canEnroll = true;
    }

    const invoiceDoc = await this.ds
      .studentDocument(studentKey)
      .collection('invoices', (ref) =>
        ref
          .where('isHeader', '==', true)
          .where('isPaid.key', '==', paymentStatus.paid.key)
          .where('invoice_type.key', '==', 2)
          .where('school_fee_type.key', '==', 3)
          .orderBy('received_date', 'desc')
      )
      .get()
      .toPromise();
    const invoiceData = MappingService.pushToArray(invoiceDoc);

    if (invoiceData && invoiceData.length > 0) {
      const header = invoiceData[0];
      const invoicePaidDoc = await this.ds
        .studentDocument(studentKey)
        .collection('invoices', (ref) =>
          ref.where('headerRef', '==', header.headerRef)
        )
        .get()
        .toPromise();
      const invoicePaidData = MappingService.pushToArray(invoicePaidDoc);

      if (invoicePaidData && invoicePaidData.length > 0) {
        const detailData = invoicePaidData.filter(
          (m) =>
            !m.isHeader &&
            m.invoice_type.key === 2 &&
            m.school_fee_type.key === 3
        )[0];
        this.invoiceDetail = detailData;

        const detail = detailData ? detailData : null;
        if (
          detail &&
          (detail.issue_term.key === termKey ||
            detail.payment_term.key === termKey)
        )
          canEnroll = true;
      }
    }
    this.loading = false;
    return canEnroll;
  }

  async checkUnpaidPayment(
    studentKey: string,
    programKey: string,
    termKey: string
  ) {
    this.loading = true;
    let canEnroll = false;
    this.invoiceDetail = null;
    const scholarshipDoc = await this.ds
      .studentDocument(studentKey)
      .collection('scholarships', (ref) =>
        ref.where('program.key', '==', programKey)
      )
      .get()
      .toPromise();
    const scholarshipData = MappingService.pushToArray(scholarshipDoc);
    if (scholarshipData && scholarshipData.length > 0) {
      const { percentage, loan } = scholarshipData[0];
      if (percentage + loan === 100) canEnroll = true;
    }

    const invoiceDoc = await this.ds
      .studentDocument(studentKey)
      .collection('invoices', (ref) =>
        ref
          .where('isHeader', '==', true)
          .where('invoice_type.key', '==', 2)
          .where('school_fee_type.key', '==', 3)
      )
      .get()
      .toPromise();
    let invoiceData = MappingService.pushToArray(invoiceDoc);
    invoiceData = MappingService.orderByDesc(invoiceData, 'page_key');

    if (invoiceData && invoiceData.length > 0) {
      const header = invoiceData[0];
      const invoicePaidDoc = await this.ds
        .studentDocument(studentKey)
        .collection('invoices', (ref) =>
          ref.where('headerRef', '==', header.headerRef)
        )
        .get()
        .toPromise();
      const invoicePaidData = MappingService.pushToArray(invoicePaidDoc);

      if (invoicePaidData && invoicePaidData.length > 0) {
        const detailData = invoicePaidData.filter(
          (m) =>
            !m.isHeader &&
            m.invoice_type.key === 2 &&
            m.school_fee_type.key === 3
        )[0];
        this.invoiceDetail = detailData;

        const detail = detailData ? detailData : null;
        if (
          detail &&
          (detail.issue_term.key === termKey ||
            detail.payment_term.key === termKey)
        )
          canEnroll = true;
      }
    }

    this.loading = false;
    return canEnroll;
  }

  async fetchScholarship() {
    this.process = true;
    const { student } = this.selectedAdmission;
    const scholarshipDoc = await this.ds
      .scholarshipByStudent(student.key)
      .get()
      .toPromise();
    this.scholarships = MappingService.orderBy(
      MappingService.pushToArray(scholarshipDoc),
      'page_key'
    );
    this.process = false;
  }

  @observable fetchStudentAdmissionsRef: any = null;
  @observable loadingAdmission: boolean = true;
  @action
  fetchStudentAdmissions(studentKey, callback?) {
    this.fetchStudentAdmissionsRef = this.ds
      .admissionByStudentRef(studentKey)
      .valueChanges()
      .subscribe((docs) => {
        this.admissions = docs;
        this.loadingAdmission = false;
        if (callback) callback(docs);
      });
  }

  @action
  async fetchStudentBatches(studentKey, admissionKey, callback?) {
    if (!admissionKey) return;
    if (this.selectedAdmission) {
      const { program_academic } = this.selectedAdmission;
      this.program = program_academic && program_academic.program;
      const batchDoc = program_academic && this.student[this.program.key];
      if (batchDoc) {
        this.fetchStudentRef = this.ds
          .batchLevelRef()
          .doc(batchDoc.key)
          .valueChanges()
          .subscribe((doc) => {
            this.studentBatch = doc;
          });
      }
      this.loading = false;
      if (callback) callback(this.selectedAdmission);
      return;
    }
  }

  @observable fetchStudentRef: any = null;
  @action
  async fetchStudent(studentKey: any, admissionKey: any, callback?) {
    this.loading = true;
    const admissionDoc = await this.ds
      .admissionDocRef(admissionKey)
      .get()
      .toPromise();
    this.selectedAdmission = pushToObject(admissionDoc);
    this.fetchStudentRef = this.ds
      .studentDocument(studentKey)
      .valueChanges()
      .subscribe((docs) => {
        this.student = docs;
        this.loading = false;
        if (callback) callback(docs);
      });
  }


  @action
  async fetchStudentDoc(studentKey: any, callback?) {
    this.loading = true;
    const ref = this.ds.studentDocument(studentKey);
    this.student = pushToObject(await ref.get().toPromise());
    if (callback) callback(this.student);
    this.loading = false;

  }

  @action
  saveCustomAttendance(studentKey: string, item: any, callback: any) {
    const batch = this.ds.batch();
    const ref = this.ds.studentDocument(studentKey).ref;
    batch.update(ref, item);
    batch
      .commit()
      .then(() => {
        callback(true, null);
        this.process = false;
      })
      .catch((error) => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  async fetchStudentAdmission(admissionKey) {
    const admissionDoc = await this.ds
      .admissionDocRef(admissionKey || 'NA')
      .get()
      .toPromise();
    this.selectedAdmission = pushToObject(admissionDoc);
    this.program =
      (this.selectedAdmission &&
        this.selectedAdmission.program_academic &&
        this.selectedAdmission.program_academic.program) ||
      null;
    return this.selectedAdmission;
  }

  @action
  async fetchAdmission(admissionKey, studentKey, callback) {
    const admissionDoc = await this.ds
      .admissionDocRef(admissionKey)
      .get()
      .toPromise();
    if (admissionDoc.exists) {
      this.selectedAdmission = MappingService.pushToObject(admissionDoc);
      const { program_academic } = this.selectedAdmission;
      this.program = program_academic.category;
      this.loading = false;
      callback(this.selectedAdmission);
    } else {
      const admissionDocs = await this.ds
        .admissionByStudentRef(studentKey)
        .get()
        .toPromise();
      this.admissions = MappingService.orderByDesc(
        MappingService.pushToArray(admissionDocs),
        'program.order'
      );
      if (!admissionDocs.empty) {
        this.selectedAdmission = this.admissions[0];
        this.program = this.selectedAdmission.program_academic.category;
        await this.ds
          .studentDocument(studentKey)
          .update({
            program_academic: this.selectedAdmission.program_academic,
          })
          .then(() => {
            callback(this.selectedAdmission);
            this.router.navigate([
              `/institute-and-center/${this.selectedAdmission.key}/${studentKey}/enrollment`,
            ]);
          });
      } else {
        callback(null);
      }
    }
  }

  @action
  fetchReceipt(studentKey, admissionKey) {
    this.loading = true;
    this.receipts = [];
    this.unpaidReceipts = [];
    this.dropStudents = [];
    this.ds
      .studentDocument(studentKey)
      .collection(
        'invoices',
        (ref) => ref
        // .where("program.admissionKey", "==", admissionKey)
      )
      .valueChanges()
      .subscribe((docs) => {
        if (docs.length > 0) {
          const data = MappingService.orderByDesc(docs, 'create_date');
          this.receipts = data.filter(
            (m) =>
              !m.isVoid && m.isHeader && m.isPaid.key === paymentStatus.paid.key
          );
          this.unpaidReceipts = data.filter(
            (m) =>
              !m.isVoid &&
              m.isHeader &&
              m.isPaid.key === paymentStatus.unpaid.key
          );
          this.dropStudents = data.filter((m) => m.isVoid && m.isHeader);
        }
        this.loading = false;
      });
  }

  @action
  fetchEnrollment(studentKey, admissionKey, termKey) {
    this.loading = true;
    this.ds
      .studentDocument(studentKey)
      .collection('institute_enrollment', (ref) =>
        ref
          .where('term.key', '==', termKey)
          .where('program.admissionKey', '==', admissionKey)
          .orderBy('page_key')
      )
      .valueChanges()
      .subscribe((docs) => {
        this.data = docs;
        this.empty = docs.length === 0;
        this.loading = false;
      });
  }

  @action
  fetchStudentSchedule(studentKey: string, term: any, admission: any) {
    this.loading = true;
    const { institute, key } = term;
    this.schedules = [];
    this.schedulesBatch = null;
    this.ds
      .studentDocument(studentKey)
      .valueChanges()
      .subscribe(async (doc) => {
        const studentData = doc;
        const batch = studentData[institute.key];
        if (batch) {
          const batchData = await this.ds
            .batchLevelRef()
            .doc(batch.key)
            .get()
            .toPromise();
          this.schedulesBatch = MappingService.pushToObject(batchData);
          this.ds
            .batchLevelRef()
            .doc(batch.key)
            .collection('schedules', (ref) => ref.where('term.key', '==', key))
            .valueChanges()
            .subscribe((docs) => {
              this.schedules = docs;
              this.loading = false;
            });
        } else {
          this.schedules = [];
          this.schedulesBatch = null;
          this.loading = false;
        }
      });
  }

  @action
  async fetchSelectedSchedule(
    studentKey: string,
    academicYear: any,
    admission: any
  ) {
    this.loading = true;
    this.schedules = [];
    this.schedulesBatch = null;
    this.ds
      .studentDocument(studentKey)
      .valueChanges()
      .subscribe(async (doc) => {
        const batchData = await this.ds
          .studentDocument(studentKey)
          .collection('student_batch_movement', (ref) =>
            ref.where('academicYear.key', '==', academicYear.key)
          )
          .get()
          .toPromise();
        let batchDoc = MappingService.pushToArray(batchData);
        batchDoc = MappingService.orderByDesc(batchDoc, 'create_date');

        let batch = null;
        if (batchDoc && batchDoc.length > 0) {
          batch = batchDoc[0].batchNew;
        }
        if (batch) {
          const studentInBatchDocs = await this.ds
            .batchLevelRef()
            .doc(batch.key)
            .collection('students', (ref) =>
              ref.where('student.key', '==', studentKey)
            )
            .get()
            .toPromise();
          const studentInBatchData =
            MappingService.pushToArray(studentInBatchDocs);
          if (studentInBatchData && studentInBatchData.length > 0) {
            const batchData = await this.ds
              .batchLevelRef()
              .doc(batch.key)
              .get()
              .toPromise();
            this.schedulesBatch = MappingService.pushToObject(batchData);

            this.ds
              .batchLevelRef()
              .doc(batch.key)
              .collection('schedules', (ref) =>
                ref.where('academicYear.key', '==', academicYear.key)
              )
              .valueChanges()
              .subscribe((docs) => {
                this.schedules = MappingService.orderBy(
                  docs,
                  'session.fromHoursNumber'
                );
                this.loading = false;
              });
          } else {
            this.schedules = [];
            this.schedulesBatch = null;
            this.loading = false;
          }
        } else {
          this.schedules = [];
          this.schedulesBatch = null;
          this.loading = false;
        }
      });
  }

  // @action
  // async changeStudentBatch(item: IEnrollment, batchLevel: any, callback) {
  //   this.process = true;
  //   const { student } = item;
  //   const studentInBatchDoc = await this.ds.batchLevelStudentRef(this.studentBatch.key, student.key).get().toPromise();
  //   if (!studentInBatchDoc.empty) {
  //     const currentEnrollment = MappingService.pushToArray(studentInBatchDoc)[0];
  //     const batch = this.ds.batch();
  //     const batchRef = this.ds.batchLevelFireRef().doc(this.studentBatch.key).collection("students");
  //     const newBatchRef = this.ds.batchLevelFireRef().doc(batchLevel.key).collection("students");
  //     const studentRef = this.ds.studentFireS(student.key);
  //     const { category } = this.selectedAdmission.program_academic;
  //     batch.set(newBatchRef.doc(currentEnrollment.key), {
  //       ...currentEnrollment,
  //       add_batch_by: item.create_by,
  //       add_batch_date: item.create_date,
  //       group: MappingService.batchObj(batchLevel),
  //     });
  //     batch.update(studentRef, {
  //       [category.key]: MappingService.batchObj(batchLevel),
  //       update_by: item.create_by,
  //       update_date: item.create_date
  //     })
  //     batch.delete(batchRef.doc(currentEnrollment.key))
  //     batch.commit().then(() => {
  //       this.process = false;
  //       callback(true, null);
  //     })
  //       .catch(error => {
  //         callback(false, error);
  //         this.process = false;
  //       });
  //   }
  //   else {
  //     callback(false, "No batch");
  //     this.process = false;
  //   }
  // }

  // @action
  // async margeBatchSchedule(item: IEnrollment, batchLevel: any, callback) {
  //   this.process = true;
  //   const { student } = item;
  //   const studentInBatchDoc = await this.ds.batchLevelStudentRef(this.studentBatch.key, student.key).get().toPromise();
  //   if (!studentInBatchDoc.empty) {
  //     const currentEnrollment = MappingService.pushToArray(studentInBatchDoc)[0];
  //     const batch = this.ds.batch();
  //     const batchRef = this.ds.batchLevelFireRef().doc(this.studentBatch.key).collection("students");
  //     const newBatchRef = this.ds.batchLevelFireRef().doc(batchLevel.key).collection("students");
  //     const studentRef = this.ds.studentFireS(student.key);
  //     const { category } = this.selectedAdmission.program_academic;
  //     batch.set(newBatchRef.doc(currentEnrollment.key), {
  //       ...currentEnrollment,
  //       add_batch_by: item.create_by,
  //       add_batch_date: item.create_date,
  //       group: MappingService.batchObj(batchLevel),
  //     });
  //     batch.update(studentRef, {
  //       [category.key]: MappingService.batchObj(batchLevel),
  //       update_by: item.create_by,
  //       update_date: item.create_date
  //     })
  //     batch.delete(batchRef.doc(currentEnrollment.key))
  //     // batch.commit().then(() => {
  //     //   this.process = false;
  //     //   callback(true, null);
  //     // })
  //     //   .catch(error => {
  //     //     callback(false, error);
  //     //     this.process = false;
  //     //   });
  //   }
  //   else {
  //     callback(false, "No batch");
  //     this.process = false;
  //   }
  // }

  // @action
  // async setStudentBatch(item: IEnrollment, newBatch: any, callback) {
  //   this.process = true;
  //   const batch = this.ds.batch();
  //   const batchRef = this.ds.batchLevelFireRef();
  //   const batchMovementRef = this.ds.instituteStudentBatchMovementRef();
  //   const { student, create_by, create_date, program, } = item;
  //   const studentNewBatchDoc = await this.ds.batchLevelRef().doc(newBatch.key).collection("students", ref => ref.where("student.key", "==", student.key)).get().toPromise();
  //   const comingStudentDoc = await this.ds.instituteNoScheduleStudentRef(student.key).get().toPromise();
  //   const comingStudentData = MappingService.pushToArray(comingStudentDoc);
  //   const testingStudentDoc = await this.ds.scheduleInProgressStudentRef(student.key).get().toPromise();
  //   const testingStudentData = MappingService.pushToArray(testingStudentDoc);

  //   const studentData = await this.ds.studentDocument(student.key).get().toPromise();
  //   const studentDoc = MappingService.pushToObject(studentData);
  //   const { category } = program;
  //   const oldBatch = studentDoc[category.key];

  //   const batchStudentMovementRef = this.ds.studentBatchMovementFireRef();

  //   const studentRef = this.ds.studentFireRef();

  //   batch.update(studentRef.doc(student.key), {
  //     [category.key]: MappingService.batchObj(newBatch),
  //     update_by: create_by,
  //     update_date: create_date
  //   })
  //   batch.set(batchRef.doc(newBatch.key).collection("students").doc(item.key), item);

  //   const movement = {
  //     ...item,
  //     key: this.ds.createId(),
  //     enrollmentKey: item.key,
  //     batch: MappingService.batchObj(newBatch),
  //     batch_old: MappingService.toNull(oldBatch),
  //   }
  //   batch.set(batchMovementRef.doc(movement.key), movement);

  //   const batchStudentMovement = {
  //     key: this.ds.createId(),
  //     batchNew: {
  //       key: newBatch.key,
  //       name: newBatch.name
  //     },
  //     batchOld: null,
  //     create_date: new Date(),
  //     create_date_key: ConvertService.toDateKey(new Date()),
  //     create_by: item.create_by,
  //     page_key: ConvertService.pageKey(),
  //     term: {
  //       key: item.term.key,
  //       name: item.term.name,
  //       code: item.term.code,
  //     },
  //     studentKey: student.key,
  //   }

  //   batch.set(batchStudentMovementRef.doc(batchStudentMovement.key), batchStudentMovement);
  //   batch.set(studentRef.doc(batchStudentMovement.studentKey).collection("student_batch_movement").doc(batchStudentMovement.key), batchStudentMovement);

  //   if (oldBatch) {
  //     const studentOldBatchDoc = await this.ds.batchLevelRef().doc(oldBatch.key)
  //       .collection("students", ref => ref.where("student.key", "==", student.key)).get().toPromise();
  //     if (!studentOldBatchDoc.empty) {
  //       const studentOldBatchData = MappingService.pushToArray(studentOldBatchDoc)[0];
  //       batch.delete(batchRef.doc(oldBatch.key).collection("students").doc(studentOldBatchData.key));
  //     }
  //   }

  //   if (!studentNewBatchDoc.empty) {
  //     const studentNewBatchData = MappingService.pushToArray(studentNewBatchDoc)[0];
  //     batch.delete(batchRef.doc(newBatch.key).collection("students").doc(studentNewBatchData.key))
  //   }

  //   if (comingStudentData && comingStudentData.length > 0) {
  //     comingStudentData.forEach(m => {
  //       batch.delete(this.ds.instituteNoScheduleStudentFireRef().doc(m.key));
  //     })
  //   }
  //   if (testingStudentData && testingStudentData.length > 0) {
  //     testingStudentData.forEach(m => {
  //       batch.delete(this.ds.scheduleInProgressFireRef().doc(m.key));
  //     })
  //   }

  //   batch.commit().then(() => {
  //     this.process = false;
  //     callback(true, null);
  //   })
  //     .catch(error => {
  //       callback(false, error);
  //       this.process = false;
  //     });
  // }

  @action
  async getAcademicPolicyHours() {
    try {
      const policyHours = pushToArray(
        await this.afs
          .collection('academics_policy_hour', (ref) =>
            ref.where('schoolKey', '==', SCHOOL.key)
          )
          .get()
          .toPromise()
      );
      return policyHours;
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async deletePermissionAndPrioritizedMovements(
    studentKey: string,
    permissionKey: string
  ) {
    try {
      // const studentKey= 'ejoAVTapifZY3ZNjI0WL';
      // const permissionKey = 'KwgPIkYx16iP8f11A5F8';
      const studentData = pushToObject(
        await this.afs.collection('students').doc(studentKey).get().toPromise()
      );
      const { campusKey, schoolKey } = studentData;

      await this.afs
        .collection('students')
        .doc(studentKey)
        .collection('permissions')
        .doc(permissionKey)
        .delete();
      await this.afs
        .collection('stores')
        .doc(schoolKey)
        .collection('campus')
        .doc(campusKey)
        .collection('permissions')
        .doc(permissionKey)
        .delete();
      await this.afs
        .collection('stores')
        .doc(schoolKey)
        .collection('permissions')
        .doc(permissionKey)
        .delete();

      // await this.afs.collection('students').doc(studentKey).collection('permissions').doc(permissionKey).update({ status: recordStatus.disables });
      // await this.afs.collection('stores').doc(schoolKey).collection('campus').doc(campusKey).collection('permissions').doc(permissionKey).update({ status: recordStatus.disables });
      // await this.afs.collection('stores').doc(schoolKey).collection('campus').doc(campusKey).collection('permissions').doc(permissionKey).update({ status: recordStatus.disables });

      const studentTimeAttendanceMovements = pushToArray(
        await this.afs
          .collection('students')
          .doc(studentKey)
          .collection('time_attendance', (ref) =>
            ref.where('permissionKey', '==', permissionKey)
          )
          .get()
          .toPromise()
      );
      const studentPrioritizedMovements = pushToArray(
        await this.afs
          .collection('students')
          .doc(studentKey)
          .collection('student_attendance_movement', (ref) =>
            ref.where('permissionKey', '==', permissionKey)
          )
          .get()
          .toPromise()
      );
      // console.log('studentPrioritizedMovements', studentPrioritizedMovements)

      Promise.all(
        studentTimeAttendanceMovements.map(async (item) => {
          await this.afs
            .collection('students')
            .doc(studentKey)
            .collection('time_attendance')
            .doc(item?.key)
            .delete();
          await this.afs
            .collection('stores')
            .doc(schoolKey)
            .collection('time_attendance')
            .doc(item?.key)
            .delete();
          return;
        })
      );
      Promise.all(
        studentPrioritizedMovements.map(async (item) => {
          await this.afs
            .collection('students')
            .doc(studentKey)
            .collection('student_attendance_movement')
            .doc(item?.key)
            .delete();
          await this.afs
            .collection('stores')
            .doc(schoolKey)
            .collection('student_attendance_movement')
            .doc(item?.key)
            .delete();
          return;
        })
      );
    } catch (error) {
      console.log(error);
    }
  }

  // @action
  // async deletePermissionAndPrioritizedMovementsV2(studentKey: string, permissionKey: string) {
  //   try {
  //     const studentData = pushToObject(await this.afs.collection('students').doc(studentKey).get().toPromise());
  //     const { campusKey, schoolKey } = studentData;

  //     // // await this.afs.collection('students').doc(studentKey).collection('permissions').doc(permissionKey).delete();
  //     // // await this.afs.collection('stores').doc(schoolKey).collection('campus').doc(campusKey).collection('permissions').doc(permissionKey).delete();
  //     // // Promise.all(studentPrioritizedMovements.map(async (item) => {
  //     //      //   await this.afs.collection('students').doc(studentKey).collection('student_attendance_movement').doc(item?.key).delete();
  //     //     //   await this.afs.collection('stores').doc(schoolKey).collection('student_attendance_movement').doc(item?.key).delete();
  //     //     //   return;
  //     //    // }));

  //     // await this.afs.collection('students').doc(studentKey).collection('permissions').doc(permissionKey).update({ status: recordStatus.disables });
  //     // await this.afs.collection('stores').doc(schoolKey).collection('campus').doc(campusKey).collection('permissions').doc(permissionKey).update({ status: recordStatus.disables });
  //     // await this.afs.collection('stores').doc(schoolKey).collection('permissions').doc(permissionKey).update({ status: recordStatus.disables });

  //     const studentPrioritizedMovements = pushToArray(await this.afs.collection('students').doc(studentKey)
  //       .collection('student_attendance_movement', ref => ref.where('permissionKey', '==', permissionKey)).get().toPromise())

  //     Promise.all(studentPrioritizedMovements.map(async (item) => {
  //       await this.afs.collection('students').doc(studentKey).collection('student_attendance_movement').doc(item?.key).update({
  //         isPrioritizedMovement: null,
  //         prioritizedShift: null,
  //         prioritizedPageKey: null,
  //         updatePermissionFrom: null,
  //         updatePermissionStatistic: null,
  //         updateToPermission: null,
  //         remark: null, remarkKey: null,

  //       });
  //       await this.afs.collection('stores').doc(schoolKey).collection('student_attendance_movement').doc(item?.key).update({
  //         isPrioritizedMovement: null,
  //         prioritizedShift: null,
  //         prioritizedPageKey: null,
  //         updatePermissionFrom: null,
  //         updatePermissionStatistic: null,
  //         updateToPermission: null,
  //         remark: null, remarkKey: null,
  //       });
  //       return;
  //     }));
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  @action
  async fetchRelatedSessionDuringPeriod(
    instructorKey: string,
    dates: Array<number>
  ) {
    try {
      const academicYear = pushToObject(await this.afs.collection('stores').doc(SCHOOL.key).collection('academic_environment').doc('academic_environment').get().toPromise());

      const sessions = await Promise.all(
        dates.map(async (date) => {
          const dt = numberToDate(date);
          var dayName = moment(dt).format('dddd').toLowerCase();
          const schedules = pushToArray(
            await this.afs
              .collection('academic_year')
              .doc(academicYear.year.key)
              .collection('schedules', (ref) => {
                return ref
                  .where('instructorKey', '==', instructorKey)
                  .where('sessionDays', 'array-contains', dayName);
              })
              .get()
              .toPromise()
          );
          const schedulesFtFilteredSessions = schedules.map((schedule) => {
            const filteredSessions = schedule.sessionItems.filter(
              (item) => item.days[dayName] === true
            );
            return { ...schedule, filteredSessions };
          });

          return { schedules: schedulesFtFilteredSessions, date, dt, dayName };
        })
      );
      return sessions;
    } catch (error) {
      console.log(error);
    }
  }

  @observable instructors = [];

  @action
  async fetchInstructors(schoolKey) {
    this.process = true;
    const list = await this.ds
      .instituteInstructorRef(schoolKey, null)
      .get()
      .toPromise();
    this.instructors = MappingService.pushToArray(list);
    this.instructors = orderBy(this.instructors, 'full_name');
    this.process = false;
  }

  @action
  searchInstructor(schoolKey, value) {
    return this.ds.instituteInstructorRef(schoolKey, value).valueChanges();
  }

  @action
  async getStudentInfoAndAdmissionData(studentId: string) {
    try {
      const studentsData = pushToArray(
        await this.afs
          .collection('students', (ref) => ref.where('puc_id', '==', studentId))
          .get()
          .toPromise()
      );
      const studentData = studentsData.length ? studentsData[0] : null;
      if (!studentData) return studentData;
      const admissionData = pushToObject(
        await this.afs
          .collection('academics_major_admission')
          .doc(studentData.program_academic.admissionKey)
          .get()
          .toPromise()
      );
      return { ...studentData, admissionData };
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async getStudentPermissionsAndItsRelatedAttendanceMovements(
    dateKey: number,
    studentKey: string
  ) {
    try {
      const campuses = pushToArray(
        await this.ds.storeRef().collection('campus').get().toPromise()
      );
      const campus = campuses.length > 0 ? campuses[0] : null;
      if (!campus) return;
      const permissions = pushToArray(
        await this.ds
          .storeRef()
          .collection('campus')
          .doc(campus.key)
          .collection('permissions', (ref) =>
            ref
              .where('studentKey', '==', studentKey)
              .where('arrayDates', 'array-contains', dateKey)
              .where('request_status.key', '==', REQUEST_STATUS.approved.key)
          )
          .get()
          .toPromise()
      );

      const permissionsAndItsTimeAttendancesAndItsMovements = await Promise.all(
        permissions.map(async (permission) => {
          let relatedTimeAttendances = pushToArray(
            await this.ds
              .storeRef()
              .collection('time_attendance', (ref) =>
                ref
                  .where('create_date_key', '==', dateKey)
                  .where('studentKey', '==', studentKey)
              )
              .get()
              .toPromise()
          );

          relatedTimeAttendances = await Promise.all(
            relatedTimeAttendances.map(async (item) => {
              const itsMovements0 = pushToArray(
                await this.ds
                  .storeRef()
                  .collection('student_attendance_movement', (ref) =>
                    ref
                      .where('timeAttendanceKey', '==', item.key)
                      .where('studentKey', '==', studentKey)
                  )
                  .get()
                  .toPromise()
              );
              // const itsMovements = itsMovements0.filter((item) => !item.isPrioritizedMovement);
              return { ...item, itsMovements: itsMovements0 };
            })
          );

          if (relatedTimeAttendances.length === 0) {
            let attendanceMovements = pushToArray(
              await this.ds
                .storeRef()
                .collection('student_attendance_movement', (ref) =>
                  ref
                    .where('create_date_key', '==', dateKey)
                    .where('studentKey', '==', studentKey)
                    .limit(1)
                )
                .get()
                .toPromise()
            );
            if (attendanceMovements.length === 0) return permission;

            const relatedTimeAttendance = pushToObject(
              await this.ds
                .storeRef()
                .collection('time_attendance')
                .doc(attendanceMovements[0].timeAttendanceKey)
                .get()
                .toPromise()
            );
            if (!relatedTimeAttendance) return permission;

            relatedTimeAttendances.push(relatedTimeAttendance);
            relatedTimeAttendances = await Promise.all(
              relatedTimeAttendances.map(async (item) => {
                const itsMovements0 = pushToArray(
                  await this.ds
                    .storeRef()
                    .collection('student_attendance_movement', (ref) =>
                      ref
                        .where('timeAttendanceKey', '==', item.key)
                        .where('studentKey', '==', studentKey)
                    )
                    .get()
                    .toPromise()
                );
                // const itsMovements = itsMovements0.filter((item) => !item.isPrioritizedMovement);
                return { ...item, itsMovements: itsMovements0 };
              })
            );
          }

          return { ...permission, relatedTimeAttendances };
        })
      );

      return permissionsAndItsTimeAttendancesAndItsMovements;
    } catch (error) {
      console.log(error);
    }
  }


  @action
  async fetchStudentAttendance(studentKey: any) {
    this.loading = true;
    const dataKey = 20230701
    try {
      // const timeAttendance = pushToArray(await this.ds.studentTimeAttendance(studentKey, dataKey).get().toPromise());
      const timeAttendance = pushToArray(await this.ds.studentAdmissionStatistic('B6myVaUjXiPo0FjSNK8B', dataKey).get().toPromise());
      return timeAttendance;
    } catch (error) {
      console.log(error);
    }


  }

}
