<form class="rows" [formGroup]="form">
  <div class="field">
    <label>Math Type
      <span class="rq-sign">*</span>
    </label>
    <input type="text" #focusInput required formControlName="text"/>
    <div *ngIf="form.controls['text'].hasError('required') && form.controls['text'].touched" class="error-message">This
      field is required.</div>
  </div>
  <button type="submit" class="enbutton primary m-r16" [disabled]="!form.valid"
    (click)="_onSubmit(form.value)">OK</button>
</form>