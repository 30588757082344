<div class="h-box-wrapper md-card" style="box-shadow: none;background-color: #dcdcdc !important;">
    <div class="m-info">
        <div class="content-box">
            <h1 class="title" *ngIf="data?.scholarship">
                {{ data?.scholarship?.text }}
            </h1>
            <p>
                Approve by <strong>{{ data?.approve_by?.full_name }}</strong> at
                {{ data?.approve_date?.toDate() | date: "medium" }}
            </p>
            <ng-container *ngIf="data?.scholarshipItems">
                <p *ngFor="let item of data?.scholarshipItems">
                    <strong>Scholarship {{item?.scholarship_type?.name}} : </strong>
                    <span *ngIf="item?.discount_type?.key ===1"> {{item?.amount}}%</span>
                    <span *ngIf="item?.discount_type?.key ===2">{{item?.amount | currency}}</span>
                </p>
            </ng-container>
            <p *ngIf="data?.percentage > 0">
                <span><strong>Scholarship: </strong>{{ data?.percentage }}% off</span>
            </p>
            <p *ngIf="data?.cash > 0">
                <span><strong>Scholarship by cash: </strong>{{ data?.cash | currency }}</span>
            </p>
            <p *ngIf="data?.loan > 0">
                <span><strong>Loan: </strong>{{ data?.loan }}%</span>
            </p>

            <div class="scrollable-table" style="padding: 0; margin-bottom: 12px;" *ngIf="data?.files?.length > 0">
                <table class="table-wrapper tb-master">
                    <thead class="table-header">
                        <tr class="table-row">
                            <th class="table-col ">No</th>
                            <th class="table-col ">File</th>
                            <th class="table-col ">Size</th>
                        </tr>
                    </thead>
                    <tbody class="table-body">
                        <tr *ngFor="let item of data?.files; let i=index;">
                            <td class="table-col ">{{i+1}}</td>
                            <td class="table-col ">
                                <a [href]="item?.downloadURl" target="blank">{{item?.name}}</a>
                            </td>
                            <td class="table-col ">
                                {{item?.fileSize|fileSize}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="s-info">
            <app-puc-button class="mat-default" (click)="onAdd.emit(data)" label="Modify Scholarship">
            </app-puc-button>
            <p class="help-block" style="margin-top: 12px;">
                Create by: <strong>{{ data?.create_by?.email }}</strong> at
                {{ data?.create_date?.toDate() | date: "medium" }}
            </p>
            <p class="help-block">
                Verify by: <strong>{{ data?.update_by?.email }}</strong> at
                {{ data?.update_date?.toDate() | date: "medium" }}
            </p>
        </div>
    </div>