import { Component, OnInit } from '@angular/core';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { Environment } from 'src/app/core/stores/environment';

@Component({
  selector: 'dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {

  constructor(public store: AuthStore
    , public evn: Environment) { }

  async ngOnInit() {
    await this.evn.fetchTermEnvironment()
  }


}
