import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({
  name: 'dateOnly'
})
export class DateOnlyPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return;
    const date = new Date(value);
    return new DatePipe('en-US').transform(date, 'dd MMM, yyyy');
  }

}


@Pipe({
  name: 'khmerDateOnly'
})
export class KhmerDatePipe implements PipeTransform {

  transform(value: any, type?: any): any {
    if (!value) return;
    let d = null;
    if (type === 'day') {
      d = moment(value).locale('km').format('D');
    }
    if (type === 'month') {
      d = moment(value).locale('km').format('MMMM');
    }
    if (type === 'year') {
      d = moment(value).locale('km').format('Y');
    }
    return d
  }

}

@Pipe({
  name: 'timeObjectToDate'
})
export class TimeObjectToDatePipe implements PipeTransform {

  transform(timeObject: any): any {

    const unixTimestamp = timeObject._seconds;
    const milliseconds = unixTimestamp * 1000 + timeObject._nanoseconds / 1000000;

    const date = new Date(milliseconds);

    const formattedDateTime = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    }).format(date);

    // const year = date.getFullYear();
    // const month = date.getMonth() + 1; // Month is 0-based, so add 1
    // const day = date.getDate();
    // const hours = date.getHours();
    // const minutes = date.getMinutes();
    // const seconds = date.getSeconds();

    console.log(date);
    return formattedDateTime
  }

}



