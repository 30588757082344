import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOutPermissibleRemarks'
})
export class FilterOutPermissibleRemarksPipe implements PipeTransform {

  transform(policyRemarks: Array<any>): unknown {
    const permissibleRemarks: Array<string> = ['P', 'M'];
    return policyRemarks.filter((policyRemark) => !permissibleRemarks.includes(policyRemark.name));
  }

}
