import { Pipe, PipeTransform } from '@angular/core';
import { chatBoxColor } from 'src/app/core/dummy/data';

@Pipe({
  name: 'getFirstLetterProfile'
})
export class GetFirstLetterProfilePipe implements PipeTransform {

  chatBoxColor: any = chatBoxColor;

  transform(name: string): any {
    if(name?.length > 0) {
      const firstLetter = name[0];
      const selectedChatBoxColor = this.chatBoxColor[firstLetter.toLowerCase()];
      return selectedChatBoxColor;
    }
    return null;
  }

}
