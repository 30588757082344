import { Pipe, PipeTransform } from '@angular/core';
import { groupBy } from 'rxjs/operators';
import { sum } from '../services/convert.service';

@Pipe({
  name: 'findGrade'
})
export class FindGradePipe implements PipeTransform {

  transform(gradeListing: any[], enrollmentKey, scheduleSubjectKey, field): unknown {
    if (gradeListing) {
      const doc = gradeListing.find(m => m.enrollmentKey === enrollmentKey && m.scheduleSubjectKey === scheduleSubjectKey)
      return doc ? (field === 'gradingScale' ? doc.gradingScale.grade : doc[field] || '-') : '-'
    }
    return null;
  }

}
@Pipe({
  name: 'sumSemesterGrade'
})
export class SumSemesterGradePipe implements PipeTransform {

  transform(gradeListing: any[], schedules: any, scheduleSubjectKey: string, studentKey: string): any {
    if (gradeListing) {
      let data = []
      schedules.map(f => {
        const scc = gradeListing.filter(g => g.scheduleSubjectKey === f.scheduleSubjectKey && g.studentKey === studentKey && g.scheduleSubjectKey === scheduleSubjectKey)
        data.push(...scc)
      });
      const result = sum(data, 'result')
      return result / 2
    }
    return null;
  }

}


@Pipe({
  name: 'sumGrade'
})
export class SumGradePipe implements PipeTransform {

  transform(gradeListing: any[], schedules: any, studentKey: string): any {
    if (gradeListing) {
      let data = []
      schedules.map(f => {
        const scc = gradeListing.filter(g => g.scheduleSubjectKey === f.scheduleSubjectKey && g.studentKey === studentKey)
        data.push(...scc)
      });
      const result = sum(data, 'result')
      return result
    }
    return null;
  }

}


@Pipe({
  name: 'sumMultiplierGrade'
})
export class SumMultiplierGradePipe implements PipeTransform {

  transform(gradeListing: any[], schedules: any, studentKey: string): any {
    if (gradeListing) {
      let data = []
      schedules.map(f => {
        const scc = gradeListing.filter(g => g.scheduleSubjectKey === f.scheduleSubjectKey && g.studentKey === studentKey)
        data.push(...scc)
      });
      const result = sum(data, 'result')
      return result / 2
    }
    return null;
  }

}


@Pipe({
  name: 'groupByGrade'
})
export class GroupByGradePipe implements PipeTransform {

  transform(gradeListing: any[], grade): any {
    if (gradeListing) {
      const doc = gradeListing.filter(m => m.gradingScale&&m.gradingScale.grade === grade)
      return doc.length;
    }
    return null;
  }

}

