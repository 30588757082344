import { Component, OnInit } from '@angular/core';
import { Environment } from 'src/app/core/stores/environment';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {

  constructor(
   public env: Environment
  ) { }

  ngOnInit(): void {
  }

}
