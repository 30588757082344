import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditEarned'
})
export class CreditEarnedPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!args) return "-";
    switch (args) {
      case 'IP':
        return "-";
      case 'I':
        return "-";
      case 'W':
        return "-";
      case 'F':
        return 0;
      case 'WF':
        return 0
      default:
        return value
    }
  }

}



@Pipe({
  name: 'gradColor'
})
export class GradeColorPipe implements PipeTransform {
  transform(grade: any) {
    switch (grade) {
      case 'A':
        return "#019345";
      case 'B':
        return "#64A346";
      case 'C':
        return "#F4B321";
      case 'D':
        return "#F6931E";
      case 'E':
        return '#E27F60';
      default:
        return null;
    }
  }
}
