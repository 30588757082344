<div class="dialog-wrapper">
    <div class="dialog-header">
        <div class="title">
            Preview
        </div>
        <div class="flex1"></div>
        <a mat-icon-button>
            <mat-icon aria-label="close" (click)="dialogRef.close('no')">close</mat-icon>
        </a>
    </div>
    <div class="dialog-content">
       <img  [src]="data" class="full-img"/>
    </div>
    <div class="dialog-footer">
        <div class="flex1"></div>
        <button mat-dialog-close class="enbutton cancel m-r16">Cancel</button>
    </div>
    <app-spinner *ngIf="loading"></app-spinner>
  
  </div>