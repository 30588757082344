import { Pipe, PipeTransform } from '@angular/core';
import { MappingService, pushToObject, pushToArray } from '../services/mapping.service';
import * as moment from 'moment'
import { DataService } from 'src/app/core/services/data.service';
@Pipe({
  name: 'totalSOnline'
})
export class TotalSOnlinePipe implements PipeTransform {
  total = 0;
  constructor(private ds: DataService) { }
  async transform(item, fdate, tdate, isAll: boolean): Promise<any> {
    const fromDateKey = moment(fdate).startOf('day').toDate();
    const toDateKey = moment(tdate).endOf('day').toDate();
    if (!item) return null;
    const data = await this.ds.academicYearRefCollection().doc(item.academicYear.key).collection('schedules').doc(item.key).collection('audit_trail',
      ref => ref.where('isTeacher', '==', false).where('create_date', '>=', fromDateKey).where('create_date', '<=', toDateKey)).get().toPromise()
    const dataByuid = MappingService.groupBy(pushToArray(data), 'uid', 'uid');
    const alldata = pushToArray(data)
    return isAll ? (dataByuid.length + '/' + alldata.length) : dataByuid.length
    // return MappingService.orderBy(item.filter(m=>m.term && m.term.key===args),"courseCode");
  }

}


@Pipe({
  name: 'studentName'
})
export class StudentNamePipe implements PipeTransform {
  transform(value: any, school?: any) {
    let student_name = value?.last_name + ' ' + value?.first_name;

    if (school?.isKhmer) {
      student_name = (value?.khmer_last_name ? value?.khmer_last_name : '') + ' ' + (value?.khmer_first_name ? value?.khmer_first_name : '')
    }

    if (school?.WesterNameOrder) {
      student_name = value?.first_name + ' ' + value?.last_name
    }

    if(!value?.last_name && !value?.first_name) {
      student_name = value?.full_name;
    }

    return student_name ? student_name : null;
  }
}
@Pipe({
  name: 'instructorName'
})
export class InstructorNamePipe implements PipeTransform {
  transform(value: any, school?: any) {
    let name = value?.last_name + ' ' + value?.first_name
    if (school?.isKhmer) {
      name = (value?.khmer_last_name ? value?.khmer_last_name : '') + ' ' + (value?.khmer_first_name ? value?.khmer_first_name : '')
    }
    if (school?.WesterNameOrder) {
      name = value?.first_name + ' ' + value?.last_name
    }
    return name ? name : null;
  }

}
