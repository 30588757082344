import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectedStudentTesting'
})
export class SelectedStudentTestingPipe implements PipeTransform {

  transform(optionKey: unknown, answers: any[], questionKey, sectionKey: number, ...args: unknown[]): unknown {
    if (optionKey) {
      switch (sectionKey) {
        case 1:
          const q = answers.find(m => m.key === questionKey);
          if (q) {
            const { studentAnswer } = q;
            return (Array.isArray(studentAnswer) && studentAnswer.find(r => r.key === optionKey)) ? true : false;
          }
          return false;
        default:
          break;
      }
    }
    return null;
  }

}
