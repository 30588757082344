<div class="dialog-wrapper">
  <div class="dialog-header">
    <div class="title">
      {{msg?.title}}
    </div>
    <div class="flex1"></div>
    <a mat-dialog-close class="close-dialog" (click)="onClose()">
      <i class="material-icons">&#xE14C;</i>
    </a>
  </div>
  <div class="dialog-content">
    <div class="form-wrapper" style="padding: 0; margin-bottom: 12px;">
      <div class="ui form">
        <div class="field">
          <label>Student ID
          </label>
          <input type="text" placeholder="Enter Current Student ID" [formControl]="studentId" class="lg-input">
        </div>
      </div>
    </div>
    <div class="form-content">
      <div class="aside-warning info">
        <i class="material-icons">warning</i>
        <span>{{msg?.subtitle}}</span>
      </div>
      <p>This student
        <strong>{{msg?.name}}</strong> {{msg?.memo}}</p>
      <p>Are you sure, you want to take this action?</p>
    </div>
  </div>
  <div class="dialog-footer">
    <div class="flex1"></div>
    <a mat-dialog-close class="enbutton cancel m-r16" mat-button (click)="onClose()">Cancel</a>
    <button type="submit" class="enbutton primary m-r16" mat-raised-button (click)="onSave()" color="warn">OK</button>
  </div>
</div>
