
import { DataService } from "./../services/data.service";
import { observable, computed, action, autorun, toJS } from "mobx";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class Search {
  @observable
  public data = null;
  @observable
  public loading = false;
  @observable
  public empty = false;
  @observable
  public done = false;
  @observable
  public fetching = false;
  // @observable public student=null;
  @observable
  filterType = null;
  public lastVisible: any = new Date();
  constructor(private ds: DataService) { }

  @action
  fetchStudent(key: string) {
    this.ds.studentDocument(key).valueChanges().subscribe(doc => {
      // this.student = doc;
    })
  }

  @action
  fetchData(schoolKey, field) {
    this.loading = true;
    this.ds
      .studentRef(schoolKey, field)
      .valueChanges()
      .subscribe(docs => {
        this.empty = docs.length === 0;
        this.data = docs;
        this.loading = false;
      });
  }

  @action
  onScroll(field, search) {
    if (this.fetching || this.done) return;
    this.fetching = true;
    this.ds
      .studentFetchRef(field, search, this.lastVisible)
      .valueChanges()
      .subscribe(docs => {
        if (docs.length) {
          this.lastVisible = docs[docs.length - 1];
        } else {
          this.done = true;
        }
        this.data = this.data.slice().concat(docs);
        this.fetching = false;
      });
  }

  @action
  search(schoolKey, campusKey, field, search) {
    if (search && search.key) {
      return this.ds.studentSearchRef(schoolKey, campusKey, field, search.puc_id).valueChanges();
    }
    return this.ds.studentSearchRef(schoolKey, campusKey, field, search).valueChanges();
  }

}
