import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, debounceTime, tap } from "rxjs/operators";
import { pucsearchFilterBy, admissionType } from 'src/app/core/dummy/stauts';
import { StudentStore } from 'src/app/core/stores/student.store';
import { Environment } from 'src/app/core/stores/environment';
import { Search } from 'src/app/core/stores/search.store';
import { Report } from 'src/app/core/stores/report.store';
import { Pages } from 'src/app/core/dummy/pages';
import { MappingService } from 'src/app/shared/services/mapping.service';

@Component({
  selector: 'app-student-panel',
  templateUrl: './student-panel.component.html',
  styleUrls: ['./student-panel.component.scss'],
  // changeDetection:ChangeDetectionStrategy.OnPush
})
export class StudentPanelComponent implements OnInit {

  icons = [
    "school",
    "local_library",
    "cloud",
    "view_carousel",
    "favorite",
    "verified_user",
  ]
  id: string;
  formFocus: boolean;
  form: FormGroup;
  search: AbstractControl;
  searchType: AbstractControl;
  filterBy = pucsearchFilterBy;
  loading: boolean = false;

  termList = [];
  constructor(
    private store: StudentStore,
    private router: Router,
    private route: ActivatedRoute,
    public env: Environment,
    public searchStore: Search,
    private fb: FormBuilder,
    public report: Report,
  ) { }

  async ngOnInit() {
    this.form = this.fb.group({
      searchType: [this.filterBy[0], [Validators.required]],
      search: [null]
    });

    this.search = this.form.controls["search"];
    this.searchType = this.form.controls["searchType"];
    if (this.searchStore.filterType) {
      this.searchType.patchValue(this.searchStore.filterType);
    }

    await this.env.fetchUserDoc();
    const { selectedSchool, selectedCampus } = this.env

    this.searchStore.fetchData(selectedSchool.key, pucsearchFilterBy[0].key);
    this.search.valueChanges
      .pipe(
        debounceTime(Pages.debounceTime),
        tap(() => (this.loading = true)),
        switchMap(value => this.searchStore.search(selectedSchool.key, selectedCampus.key, this.searchType.value.key, value))
      )
      .subscribe(results => {
        this.searchStore.data = results;
        this.loading = false;
      });

    this.store.fetchTerm();
    this.termList = await this.report.fetchTermReporting();
    this.env.selectedTerm = this.termList[0];
  }

  onSelectedTerm(item) {
    this.env.selectedTerm = item;
    const { term_type } = this.env.selectedTerm;
    this.store.course = null;
    this.store.schedules = null;
    if (this.store.selectedAdmission) {
      const { admission_type, student } = this.store.selectedAdmission;
      if (admission_type.key === admissionType.institute.key && term_type.key == "english_term") {
        this.store.fetchEnglishStudentSchedule(student.key, this.env.selectedTerm, this.store.selectedAdmission);
      } else {
        this.store.fetchStudentSchedule(this.env.selectedTerm.key, student.key);
      }
    }
  }

  _goBack() {
    this.router.navigate(['/']);
  }

  displayItem(item: any): string {
    if (this.searchType) {
      const { key } = this.searchType.value;
      return item ? item[key] : item;
    }
    return item ? item.puc_id : item;
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) {
      return o1.text === o2.text;
    }
  }
  _onFocus(yes) {
    this.formFocus = yes;
  }

  _onSearch(item: any) {
    const { search, searchType } = item;
    if (search && search.key) {
      this.router.navigate(["/student/" + search.key + "/payment"]);
    }
    else {
      const students = MappingService.filter(this.searchStore.data, searchType.key, search);
      if (students.length > 0) {
        const s = students[0];
        this.router.navigate(["/student/" + s.key + "/payment"]);
      }
    }
  }

  _selectionChange(event) {
    const { value } = event;
    this.searchStore.filterType = value;
  }

  _optionSelected(item: any) {
    this.router.navigate(["/student/" + item.key + "/payment"]);
  }
}
