import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { reportFilterByCampus } from 'src/app/core/dummy/stauts';
import { Campus } from 'src/app/core/stores/campus.store';

@Component({
  selector: 'app-campus-panel',
  templateUrl: './campus-panel.component.html',
  styleUrls: ['./campus-panel.component.scss']
})
export class CampusPanelComponent implements OnInit {

  tabs = reportFilterByCampus;
  cstab;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public campus: Campus,

  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.cstab = this.tabs.map(f => ({ path: '/campus/' + param.id + f.slabel, label: f.label, icon: f.icon }));
      this.campus.fetchCampusDoc(param.id, data => {})
    })
  }

  _goBack() {
    this.router.navigate(['/finance']);
  }
}
