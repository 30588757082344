import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { SCHOOL } from 'src/app/core/dummy/config';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-get-student-info',
  templateUrl: './get-student-info.component.html',
  styleUrls: ['./get-student-info.component.scss']
})
export class GetStudentInfoComponent implements OnInit {

  @Input() key: string;
  @Input() field: string;
  @Input() isDate: boolean;
  @Input() other: string;
  data: any;
  school = SCHOOL;

  constructor(public ds: DataService) { }

  private subscribe: Subscription

  async ngOnInit() {
    this.subscribe = this.ds.studentDocument(this.key).snapshotChanges().subscribe(async doc => {
      this.data = doc.payload.data();
    })

    
    // if (this.other !== '' && this.data) {
    //   this.data = this.data[this.other];
    // }

    // if (!this.data) this.data = "Cambodian";
  }
  ngOnDestroy(): void {
    if (this.subscribe) this.subscribe.unsubscribe()
  }

}
