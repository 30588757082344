<div class="q-topic" *ngIf="textArray.length>0">
  <div class="rows">
    <div class="flex1">
      <ng-container *ngFor="let item of textArray">
        <span [ngClass]="canEdit?'match-text':''" (click)="_onText(item)" *ngIf="!item?.isFill && item?.type?.key===1"
          [innerHTML]="item?.text">
        </span>
        <span [ngClass]="canEdit?'match-text':''" (click)="_onLaText(item)" *ngIf="!item?.isFill && item?.type?.key===2">
          <ng-katex style="font-size: 21px;" [equation]="item?.text"></ng-katex>
        </span>
        <span [ngClass]="canEdit?'match-text':''" (click)="_onText(item)" *ngIf="item?.isFill && item?.type?.key===1">
          <span style="border-bottom:1px solid #333;padding-bottom:3px;" [innerHTML]="item?.text"></span>
        </span>
        <span [ngClass]="canEdit?'match-text':''" (click)="_onLaText(item)" *ngIf="item?.isFill && item?.type?.key===2">
          <span style="border-bottom:1px solid #333; padding-bottom:3px ;">
            <ng-katex style="font-size: 21px;" [equation]="item?.text"></ng-katex>
          </span>
        </span>
        <span [ngClass]="canEdit?'match-text':''" (click)="_onBreak(item)" *ngIf="canEdit && item?.type?.key===10">
          <span class="material-icons">
            keyboard_return
          </span>
        </span>
        <div *ngIf="item?.type?.key===5" style="width: 100px;
        display: inline-flex;
        border-bottom: solid 1px solid;
        border: solid 3px #444;"></div>
        <br *ngIf="item?.type?.key===10" />
      </ng-container>
    </div>
  </div>
</div>