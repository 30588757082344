import { Pipe, PipeTransform } from '@angular/core';
import { SCHOOL } from 'src/app/core/dummy/config';

@Pipe({
  name: 'getSchoolConfig'
})
export class GetSchoolConfigPipe implements PipeTransform {

  transform(value: string, field: string): any {
    if (value)
      return SCHOOL[value];
  }

}
