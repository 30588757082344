<div class="print-wrapper no-shadow p-0" style="margin: 0;">
    <div class="row-header">
      <div class="title">
        Print Preview
      </div>
      <div class="btn-print">
        <button mat-icon-button (click)="print()" class="print-btn"><i class="material-icons">&#xE8AD;</i></button>
      </div>
      <div class="close-btn-report ml-5" (click)="dialogRef.close('yes')">
        <i class="material-icons">&#xE14C;</i>
      </div>
    </div>
  
    <div class="row-body">
      <app-payment-print-template *ngIf="payment?.receiptHeader" [user]="userStore?.user"></app-payment-print-template>
    </div>
  </div>