import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WebcamImage } from 'ngx-webcam';

@Component({
  selector: 'app-open-camera-dialog',
  templateUrl: './open-camera-dialog.component.html',
  styleUrls: ['./open-camera-dialog.component.scss']
})
export class OpenCameraDialogComponent implements OnInit {

  finalImage: File = null;
  webcamImage: WebcamImage | null = null;
  webcamImage_imageAsBase64: any = null;

  constructor(
    public dialogRef: MatDialogRef<OpenCameraDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.clear();
  }

  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
    this.webcamImage_imageAsBase64 = webcamImage.imageAsBase64;
  }

  croppedImageEvent(event) {
    this.finalImage = event;
    if (!event) {
      this.webcamImage = null;
      this.webcamImage_imageAsBase64 = null;
    }
  }

  btnSaveCroppedImageClicked() {
    this.dialogRef.close(this.finalImage);
    this.clear();
  }

  clear() {
    this.finalImage = null;
    this.webcamImage = null;
    this.webcamImage_imageAsBase64 = null;
  }

}
