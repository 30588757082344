<div class="dialog-wrapper">
  <div class="dialog-header">
    <div class="title">
      {{data?.title}}
    </div>
    <div class="flex1"></div>
    <a mat-dialog-close class="close-dialog" (click)="dialogRef.close()">
      <i class="material-icons">&#xE14C;</i>
    </a>
  </div>
  <div class="dialog-content">
    <div class="form-content">
      <div class="aside-warning">
        <i class="material-icons">warning</i>
        <span>{{data?.subTitle}}</span>
      </div>
      <p>{{data?.description}}</p>
    </div>
  </div>
  <div class="dialog-footer">
    <div class="flex1"></div>
    <button mat-dialog-close type="button" class="enbutton cancel m-r16" mat-button
      (click)="dialogRef.close()">Cancel</button>
    <button type="submit" class="enbutton delete-btn m-r16" mat-raised-button (click)="onClose()"
      color="warn">Continue</button>
  </div>
</div>
