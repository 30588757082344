import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-account-student',
  templateUrl: './confirm-account-student.component.html',
  styleUrls: ['./confirm-account-student.component.scss']
})
export class ConfirmAccountStudentComponent implements OnInit {
  msg: any;
  constructor(public dialogRef: MatDialogRef<ConfirmAccountStudentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  studentId = new FormControl(null);

  ngOnInit() {
    this.msg = this.data;
  }

  onClose() {
    this.dialogRef.close('no')
  }

  onSave() {
    this.dialogRef.close(this.studentId.value)
  }

}
