export const NUBB_SCHOOL = {
  key: 'national_university_of_battambang',
  name: "National University of Battambang",
  khName: "សាកលវិទ្យាល័យជាតិបាត់ដំបង",
  email: "info@nubb.edu.kh",
  website: "http://nubb.edu.kh/",
  img: "/assets/images/nubb.png",
  maxID: 7,
  examByCampus: true,
  isNGS: false,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  isRoundUpGradeSheet: false,
  isGradingColor: true,
  isHaveConvertGrading: false,
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    person: 'ជំនួយការរដ្ឋបាល',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    phone: ''
  },
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: false,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,
}
export const BANAN_SCHOOL = {
  key: 'bvs_school',
  name: "Borey Vichear School",
  name_kh: "សាលា​ចំណេះទូទៅ បុរីវិជ្ជា",
  shortName: "BVS",
  email: "Info@boreyvichearschool.edu.kh",
  website: "www.boreyvichearschool.edu.kh",
  img: "/assets/images/bvs.png",
  isKhmer: true,
  isNGS: true,
  hasCardPrinting: true,
  selfPermissionToEachSession: false,
  hasAttendanceStatManagement: false,
  noteForAttendanceDetail: false,

}
export const SCHOOLLINK_SCHOOL = {
  key: 'school_link',
  name: "School Link Company",
  khName: "ក្រុមហ៊ុន ស្គូលលីង",
  email: "Info@schoollink.edu.kh",
  website: "www.schoollink.edu.kh",
  img: "/assets/images/schoollink_S.png",
  maxID: 7,
  examByCampus: false,
  isNGS: false,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  isRoundUpGradeSheet: false,
  isGradingColor: true,
  isHaveConvertGrading: false,
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    person: 'ជំនួយការរដ្ឋបាល',
    phone: ''
  },
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: false,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,
}
export const PAMA_SCHOOL = {
  key: 'pama_school',
  name: "PAMA International School",
  khName: "សាលាប៉ាម៉ាអន្តរជាតិ",
  email: "info@pamainternationalschool.com",
  website: "https://pamainternationalschool.com",
  img: "/assets/images/pama.png",
  maxID: 9,

}
export const EWIS_SCHOOL = {
  key: 'ewis_school',
  name: "East-West International School",
  name_kh: "សាលាអន្តរជាតិ អុិសវ៉េស",
  email: "Info@ewiscambodia.edu.kh",
  website: "www.ewiscambodia.edu.kh",
  img: '/assets/images/ewis.png',
  WesterNameOrder: true
}
export const SPK_SCHOOL = {
  key: 'sovannaphumi_kids',
  name: "Sovannaphumi Kids",
  khName: "កុមារសុវណ្ណភូមិ",
  email: "info.spk@sovannaphumi.edu.kh",
  website: "spk1.ntcgroup.com.kh/",
  img: "/assets/images/spk.png",
  maxID: 7,
  examByCampus: true,
  isNGS: false,
  isShowCampus: true,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  isRoundUpGradeSheet: false,
  isGradingColor: true,
  isHaveConvertGrading: false,
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    person: 'ជំនួយការរដ្ឋបាល',
    phone: ''
  },
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: false,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,
}
export const BVS_SCHOOL = {
  key: 'bvs_school',
  name: "Borey Vichear School",
  khName: "សាលា​ចំណេះទូទៅ បុរីវិជ្ជា",
  email: "Info@boreyvichearschool.edu.kh",
  website: "www.boreyvichearschool.edu.kh",
  img: "/assets/images/bvs.png",
  maxID: 7,
  examByCampus: false,
  isNGS: false,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  isRoundUpGradeSheet: false,
  isGradingColor: true,
  isHaveConvertGrading: false,
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    person: 'ជំនួយការរដ្ឋបាល',
    phone: ''
  },
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: false,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,
}
export const MANNER_SCHOOL = {
  key: 'manner_school',
  name: "Manner International School",
  name_kh: "សាលាអន្តរជាតិ មែនន័រ",
  shortName: "MIS",
  email: "mannerinternationalschool.mis@gmail.com",
  website: "http://www.mannerinternationalschool.com/",
  img: "/assets/images/manner.png",
  isKhmer: false,
  isNGS: false,
  hasCardPrinting: true,
  selfPermissionToEachSession: false,
  hasAttendanceStatManagement: false,
}
export const APIS_SCHOOL = {
  key: 'apis_school',
  name: "American Pacific International School",
  name_kh: "សាលាអន្តរជាតិអាមេរិកាំងប៉ាស៊ីហ្វិក",
  email: "info@apis.edu.kh",
  website: "www.apis.edu.kh",
  shortName: "APIS",
  img: '/assets/images/apis.png',
  isKhmer: false,
  isNGS: false,
  hasCardPrinting: true,
  selfPermissionToEachSession: false,
  noteForAttendanceDetail: true,
  hasAttendanceStatManagement: false
}
export const SSW_SCHOOL = {
  key: 'ngs',
  name: "Preah Sisowath High School (New Generation School)",
  name_kh: "វិទ្យាល័យព្រះស៊ីសុវត្ថិ សាសារៀនជំនាន់ថ្មី",
  department: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  email: "Info@preahsisowath.edu.kh",
  website: "www.preahsisowath.edu.kh",
  img: "/assets/images/ngs.png",
  imgFull: "/assets/images/ngs.png",
  address: 'មហាវិថី​​​ ព្រះនរោត្តម, សង្កាត់ផ្សារថ្មី៣, ខណ្ឌដូនពេញ, ភ្នំពេញ, កម្ពុជា.',
  phone: '(+855) 89 899 632',
  isNGS: true,
  isPSIS: false,
  isKhmer: true,
  hasCardPrinting: true,
  selfPermissionToEachSession: false,
  hasAttendanceStatManagement: true,
  noteForAttendanceDetail: false,
  newFilePath: 'ngsclouddev.appspot.com',
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    person: 'អ្នកធ្វើតារាង',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    phone: '012515099/070356800',
    insteadSP: 'ជ.នាយកវិទ្យាល័យ',
    deputySP: 'នាយករងទទួលបន្ទុកសាលារៀនជំនាន់ថ្មី',
    submitTo: '',
    school: ''
  },

}

export const YKT_SCHOOL = {
  key: 'ngs',
  name: "Preah Yukunthor High School (New Generation School)",
  name_kh: "វិទ្យាល័យព្រះយុគន្ធរ សាលារៀនជំនាន់ថ្មី",
  department: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  email: "Info@preahyukunthor.edu.kh",
  website: "www.preahyukunthor.edu.kh",
  img: "/assets/images/ngs_ykt.png",
  imgFull: "/assets/images/ngs_ykt.png",
  address: 'មហា​វិថី​ព្រះ​មុនីវង្ស​ សង្កាត់​ បឹង​កេង​កង​ ២​ ខណ្ឌ​បឹង​កេង​កង​ រាជ​ធានី​ ភ្នំពេញ',
  phone: '(+855) 12 399 606',
  isNGS: true,
  isPSIS: false,
  isKhmer: true,
  hasCardPrinting: true,
  selfPermissionToEachSession: false,
  hasAttendanceStatManagement: true,
  noteForAttendanceDetail: false,
  newFilePath: 'ngsclouddev.appspot.com',
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    person: 'អ្នកធ្វើតារាង',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    phone: '012515099/070356800',
    insteadSP: 'ជ.នាយកវិទ្យាល័យ',
    deputySP: 'នាយករងទទួលបន្ទុកសាលារៀនជំនាន់ថ្មី',
    submitTo: '',
    school: ''
  },

}

export const PREKLEAP_SCHOOL = {
  key: 'ngs-preakleap',
  name: "Prek Leap High School (New Generation School)",
  name_kh: "វិទ្យាល័យព្រែកលៀប សាលារៀនជំនាន់ថ្មី",
  img: "/assets/images/ngs_prekleap.png",
  shortName: "NGSPL",
  isNGS: true,
  isKhmer: true,
  hasCardPrinting: true,
  isPSIS: false,
  noteForAttendanceDetail: true,
  selfPermissionToEachSession: false,
  hasAttendanceStatManagement: true
}
export const PREKANCHANH_SCHOOL = {
  key: 'ngs-prek-anchanh',
  name: "Chea Sim Prek Anhchanh High School (New Generation School)",
  name_kh: "វិទ្យាល័យ សម្តេចអគ្គមហាធម្មពោធិសាល ជាស៊ីម ព្រែកអញ្ចាញ សាលារៀនជំនាន់ថ្មី",
  img: "/assets/images/ngs_prek_anchanh.png",
  shortName: "NGSPAC",
  isNGS: true,
  isKhmer: true,
  hasCardPrinting: true,
  isPSIS: false,
  selfPermissionToEachSession: false,
  hasAttendanceStatManagement: true
}
export const PEAMCHIKANG_SCHOOL = {
  key: 'ngs_peam_chikang',
  name: "Hun Sen Peam Chikang High School (New Generation School)",
  name_kh: "វិទ្យាល័យ ហ៊ុន សែន ពាមជីកង សាលារៀនជំនាន់ថ្មី",
  shortName: "NGSPCK",
  img: "/assets/images/ngs_peam_chikang.png",
  isNGS: true,
  isKhmer: true,
  isPSIS: false,
  hasCardPrinting: false,
  selfPermissionToEachSession: false,
  hasAttendanceStatManagement: true
}
export const KAMPONGCHAM_SCHOOL = {
  key: 'ngs_kampong_cham',
  name: "Hun Sen Kampong Cham High School (New Generation School)",
  khName: "វិទ្យាល័យ ហ៊ុន សែន កំពង់ចាម សាលារៀនជំនាន់ថ្មី",
  name_kh: "វិទ្យាល័យ ហ៊ុន សែន កំពង់ចាម សាលារៀនជំនាន់ថ្មី",
  shortName: "NGSKPC",
  img: "/assets/images/ngs_kampong_cham.png",
  isNGS: true,
  isKhmer: true,
  isPSIS: false,
  hasCardPrinting: false,
  selfPermissionToEachSession: true,
  hasAttendanceStatManagement: false

}
export const SPS_SCHOOL = {
  key: 'sovannaphumi_school',
  name: "Sovannaphumi School",
  name_kh: "សាលារៀនសុវណ្ណភូមិ",
  email: "Info@sovannaphumin.edu.kh",
  website: "www.sovannaphumin.edu.kh",
  img: '/assets/images/sps.png',
  imgFull: '/assets/images/sps.png',
  isNGS: false,
  isKhmer: false,
  isPSIS: false,
  hasCardPrinting: true,
  selfPermissionToEachSession: false,
  hasAttendanceStatManagement: true,
  noteForAttendanceDetail: false,
}

export const SM_SCHOOL = {
  key: 'ngs',
  name: "Paññāsāstra International School",
  name_kh: "សាលាបញ្ញាសាស្ត្រអន្តរជាតិ",
  department: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  email: "Info@preahsisowath.edu.kh",
  website: "www.preahsisowath.edu.kh",
  img: "../assets/images/psis.png",
  profile_testing: "../assets/images/kid.jpg",
  imgFull: "/assets/images/ngs.png",
  address: 'មហាវិថី​​​ ព្រះនរោត្តម, សង្កាត់ផ្សារថ្មី៣, ខណ្ឌដូនពេញ, ភ្នំពេញ, កម្ពុជា.',
  phone: '(+855) 89 899 632',
  isNGS: true,
  isKhmer: true,
  hasCardPrinting: true,
  selfPermissionToEachSession: false,
  hasAttendanceStatManagement: true,
  noteForAttendanceDetail: false,
  newFilePath: 'ngsclouddev.appspot.com',
  isPSIS: true,
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    person: 'អ្នកធ្វើតារាង',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    phone: '012515099/070356800',
    insteadSP: 'ជ.នាយកវិទ្យាល័យ',
    deputySP: 'នាយករងទទួលបន្ទុកសាលារៀនជំនាន់ថ្មី',
    submitTo: '',
    school: ''
  },

}
export const SCHOOL_CONFIG = YKT_SCHOOL;

