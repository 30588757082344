import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from 'src/app/core/services/data.service';
import {  pushToArray } from '../services/mapping.service';
import * as moment from 'moment';

@Pipe({
  name: 'totalWork'
})
export class TotalWorkPipe implements PipeTransform {
  constructor(private ds: DataService) { }
  async transform(item, collection, fdate,tdate) :Promise<any>{
    const fromDateKey = moment(fdate).startOf('day').toDate();
    const toDateKey = moment(tdate).endOf('day').toDate();
    if (!item) return null;
    const data = await this.ds.academicYearRefCollection().doc(item.academicYear.key).collection('schedules').doc(item.key).collection(collection, ref => ref.where('created_at', '>=', fromDateKey).where('created_at', '<=', toDateKey).orderBy('created_at')).get().toPromise()
    const gedata = pushToArray(data)
    return gedata.length
    // return MappingService.orderBy(item.filter(m=>m.term && m.term.key===args),"courseCode");
  }

}