<div class="dialog-wrapper">
  <div class="dialog-header">
    <div class="title">
      Take Photo
    </div>
    <div class="flex1"></div>
    <a mat-dialog-close class="close-dialog" (click)="dialogRef.close('no')">
      <i class="material-icons">&#xE14C;</i>
    </a>
  </div>

  <div class="dialog-content">
    <div class="camera-wrapper" *ngIf="!webcamImage?.imageAsDataUrl">
      <app-camera-component (getPicture)="handleImage($event)"></app-camera-component>
    </div>

    <div class="image-cropper-wrapper" *ngIf="webcamImage?.imageAsDataUrl">
      <app-image-cropper-component [studentKey]="data?.studentKey" [image]="webcamImage"
        [webcamImage]="webcamImage?.imageAsDataUrl"
        (croppedImageEmit)="croppedImageEvent($event)"></app-image-cropper-component>
    </div>
  </div>
  <div class="dialog-footer" style="border-top: 1px solid #E5E5E5">
    <div class="flex1"></div>
    <a mat-dialog-close class="enbutton cancel m-r16" (click)="dialogRef.close('no')">Cancel</a>
    <button type="submit" class="enbutton primary m-r16" [disabled]="!this.finalImage"
      (click)="btnSaveCroppedImageClicked()">Save</button>
  </div>
</div>
