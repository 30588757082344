<div class="mat-dialog-full-box dark" *mobxAutorun>
  <div class="nav-profile">
    <div class="profile-header">
      <div class="header-actions">
        <button mat-button mat-icon-button (click)="_goBack()">
          <mat-icon aria-label="Example icon-button with a heart icon">arrow_back</mat-icon>
        </button>
      </div>
      <h4>
        {{ campus?.selectedCampus?.name }}
      </h4>

      <div class="header-actions">
        <!-- <div class="campus-select">
         <button>
            <i class="material-icons">schedule</i>
            <div class="name">
              {{env?.academic?.term?.code}}
            </div>
         </button>
       </div> -->
      </div>
    </div>
    <div class="profile-header col">
      <h3 *ngIf="campus?.selectedCampus">Receipt Listing Report - {{ campus?.selectedCampus?.name }}</h3>
      <div class="group-buttons" *ngIf="campus?.selectedCampus">
        
        <div class="group-mat-button" *ngFor="let item of cstab">
          <button mat-button mat-fab routerLinkActive="active" [routerLink]="[item?.path]">
            <mat-icon aria-label="Transcript">{{item.icon}}</mat-icon>
          </button>
          <span class="text-group">{{item?.label}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="apps-contain flat-full">

    <router-outlet></router-outlet>
    <!-- <app-spinner *ngIf="store?.loading"></app-spinner> -->
  </div>
</div>