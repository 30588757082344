import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReceivePaymentStore } from 'src/app/core/stores/receivepayment.store';
import { UserStore } from 'src/app/core/stores/user.store';
import { PrintService } from '../../services/print.service';

@Component({
  selector: 'app-invoice-print-form',
  templateUrl: './invoice-print-form.component.html',
  styleUrls: ['./invoice-print-form.component.scss']
})
export class InvoicePrintFormComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InvoicePrintFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public payment: ReceivePaymentStore,
    private ps: PrintService,
    public userStore: UserStore
  ) { }

  ngOnInit() {
    this.payment.fetchInvoiceItems(this.data.studentKey, this.data.headerKey, inv => { })
  }

  print() {
    this.ps.print("#printD", "a5l");
  }

}
