import { DataService } from "./../services/data.service";
import { observable, computed, action, autorun, toJS } from "mobx";
import { Injectable } from "@angular/core";
import { AuthService } from '../services/auth.service';
import { ConvertService } from 'src/app/shared/services/convert.service';
import { AuthStore } from './auth.store';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class UserStore {
  @observable public data = null;
  @observable public loading = false;
  @observable public process = false;
  @observable public user = null;
  @observable public dailyShift = null;
  @observable public shift = null;
  @observable public isOpen = false;
  @observable public role = null;
  @observable public balance = 0;
  @observable public isAdmin = false;
  @observable public isCashier = false;
  @observable public sameDay = false;

  constructor(
    private ds: DataService,
    private auth: AuthService,
    private router: Router,
  ) {
    this.fetchUser(doc => { });
  }

  @action
  clearStore() {
    this.loading = false;
    this.process = false;
    this.user = null;
    this.dailyShift = null;
    this.shift = null;
    this.isOpen = false;
    this.isAdmin = false;
    this.isCashier = false;
    this.sameDay = false;
    this.role = null;
    this.balance = 0;
  }

  @observable fetchUserRef: any = null;
  @action
  fetchUser(callback) {
    this.loading = true;
    this.auth.isLoggedIn().then(user => {
      if (user) {
        this.fetchUserRef = this.ds
          .userRef(user.uid)
          .valueChanges()
          .subscribe(docs => {
            if (docs) {
              const { role, shift, isShiftOpen, balance, petty_cash, installment, memberOf, isCashier } = docs;
              this.role = role;
              this.shift = shift;
              this.dailyShift = shift;
              this.isOpen = isShiftOpen;
              this.isCashier = isCashier;
              let cashIn = shift ? ConvertService.toNumber(shift.cash_in) : 0;
              this.balance = ConvertService.toNumber(balance) + cashIn + ConvertService.toNumber(installment) - ConvertService.toNumber(petty_cash)
              this.user = docs;
              if (memberOf.key === 0) this.isAdmin = true;
              if (this.dailyShift) {
                const { create_date_key } = this.dailyShift;
                const dayKey = ConvertService.dateKey();
                if (dayKey == create_date_key) this.sameDay = true;
              }
              this.loading = false;
              callback(this.user);
            }
          });
      } else {
        this.signOut();
        this.loading = false;
        callback(null);
      }
    }).catch(error => {
      this.signOut();
      this.loading = false;
      callback(null);
    });
  }

  @action
  signOut() {
    this.fetchUserRef && this.fetchUserRef.unsubscribe();
    this.auth.authRef().signOut().then(() => {
      this.clearStore();
      this.router.navigate(["/auth/login"]);
      localStorage.removeItem('user');
    })
  }

  updateUserCampus(user: any, campus: any) {
    this.ds.userDocRef(user.key).update({ campus: campus }).then(() => { })
  }

}
